@charset "utf-8";
#sitemap {
  border-top: rgba($color_text, 0.3) solid 1px;

  ul {
    @include listclear();

    li {
      a {
        display: block;
        text-decoration: none;
        position: relative;
        padding: .5em 1em;
        border-bottom: rgba($color_text, 0.3) solid 1px;
        @include fontsize(18);

        &:before {
          content: '';
          width: 6px;
          height: 6px;
          border: 0px;
          border-top: solid 2px $color_text;
          border-right: solid 2px $color_text;
          transform: rotate(45deg);
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }

        &:hover {
          background: $color_highlight_bg;
        }
      }
    }
  }
}
