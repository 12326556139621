@charset "utf-8";
footer {
  position: relative;
  padding: 60px 0;
  font-size: $footer_font_size + px;
  // @media (max-width: 767px) {
  //   padding: 0 0 1em;
  // }

  .fNav {
    ul {
      @include listclear();

      li {
        + li {
          border-top: 1px solid rgba($color_primary, 0.3);
        }

        a {
          display: block;
          padding: 1em 15px;
          text-decoration: none;
        }

        &.has_under {
          ul {
            display: block !important;
          }
        }
      }
    }
  }

  .copy_right {
    @include fontsize(10);
  }
}

// @media (max-width: 767px) {
//   footer {
//     ul {
//       border-top: 1px solid rgba($color_primary, 0.3);

//       li {
//         > ul {
//           border-top: 1px solid rgba($color_primary, 0.3);
//         }

//         &.has_under {
//           ul {
//             display: block !important;
//             height: auto !important;
//             a {
//               font-size: 90%;
//               padding-left: 1.5em;
//             }
//           }
//         }
//       }
//     }

//     .col {
//       margin-bottom: 0 !important;
//     }
//   }
// }

  footer {
    .fNav {
      margin-bottom: 5%;

      ul {
        width: 100%;
        text-align: left;
        border-bottom: none;

        li {
          border: none !important;

          a {
            padding: 0.2em 1em 0.2em 1em !important;
            text-decoration: none;
            position: relative;

            &:before {
              width: 8px;
              height: 8px;
              border: none;
              background: $color_secondary;
              // margin-top: -2px;
              content: "";

              position: absolute;
              top:33%;
              left: 0;
            }
          }

          > ul {
            padding-left: 0.2em;

            a {
              font-size: 90%;

              &:before {
                width: 5px;
                height: 5px;
                border: none;
                background: $color_secondary;
                border-radius: 50%;
                margin-top: -2px;
              }
            }
          }
        }
      }
    }
  }


.copy_right {
  background: $color_footer_bg;
  color:$color_text_reversal;
  @include fontsize(12);
  margin: 0;
  padding: 2em 0;
  border-top: solid 1px #323262;
  @media (max-width: 767px) {
    padding: 1em 0;
  }
}

// スマホナビゲーション折り返し設定（タブレット）
@media (min-width: 768px) and (max-width: 1023px) {
  footer nav ul li {
    &.tablet-break:after {
      content: "\A";
      white-space: pre;
    }

    &.tablet-break + li:before {
      display: none;
    }
  }
}

footer {
  background: $color_footer_bg;
  color: $color_footer_text;

  a {
    color: $color_ftnavlink;

    &:hover {
      color: $color_ftnavlink_hover;
    }
  }

  @media (min-width: 768px) {
    .container {
      .row {
        nav {
          ul {
            display: inline-block;
          }

          a {
            display: inline-block;
            padding: 0.3em;
          }
        }
      }
    }
  }
}
