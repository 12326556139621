@charset "utf-8";
//*****************共通*****************//
//画像hover時透過
//classの無い a 内の imgは全てhover時に透過となる
//透過したく場合はno-hover等適当なclassを付けて対応
a:not([class]),
a.swipebox {
  img {
    transition: all 0.3s;
  }

  &:hover {
    img {
      opacity: 0.8;
    }
  }
}

// bootstrap風のflexオプション
// rowに併記して使用すること
// https://getbootstrap.jp/docs/4.3/utilities/flex/#enable-flex-behaviors
@media (min-width: 768px) {
  .d-flex {
    display: flex;

    &.flex-wrap {
      flex-wrap: wrap;
    }

    &.flex-nowrap {
      flex-wrap: nowrap;
    }
  }

  .flex-row-reverse {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .align-items-start {
    align-items: flex-start;
  }

  .align-items-center {
    align-items: center;
  }

  .align-items-end {
    align-items: flex-end;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-between {
    justify-content: space-between;
  }
}

//トップ・下層出し分け
.dsp-home-only {
  display: none;
}

.dsp-under-only {
  display: block;
}

.home {
  .dsp-home-only {
    display: block;
  }

  .dsp-under-only {
    display: none;
  }
}

//マージン
@for $i from 1 through 5 {
  .mt-#{$i} {
    margin-top: #{$i}em;
  }
}

//TELリンクカラー
.js-tel {
  a {
    color: currentColor;
    text-decoration: none;
    @media (min-width: 768px) {
      pointer-events: none;
    }
  }
}

//ofi調整
.ofi {
  &.cover {
    img {
      object-fit: cover;
      font-family: "object-fit: cover;";
      width: 100%;
      height: 100%;
    }
  }
}

.icon-tel {
  &:before {
    content: "\f879";
    font-family: "Font Awesome 5 Free";
    margin-right: 5px;
  }
}

//*****************TOP*****************//
body.english {
  .jp-only {
    display: none;
  }
}
body:not(.english) {
  .en-only {
    display: none;
  }
}

body.home {
  h2 {
    @include fontsize(60);
    color: $color_primary;
    font-weight: normal;
    padding: 0;
    @media (max-width: 767px) {
      @include fontsize(35);
    }
    &:after {
      top: 85px;
      bottom: auto;
      @media (max-width: 767px) {
        top: 55px;
      }
    }
    .sub {
      display: block;
      color: $color_text;
      @include fontsize(28);
      padding-top: 0.8em;
      @media (max-width: 767px) {
        @include fontsize(22);
      }
    }
  }
}

.top_work {
  padding: 10% 0;

  @media (min-width: 1023px) {
    background: url(/wp-content/uploads/bg_img001.jpg) no-repeat center / cover;
  }
  @media (min-width: 981px) and (max-width: 1170px) {
    padding: 5% 0;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    background: url(/wp-content/uploads/bg_img001-1.jpg);
  }
  .col.tablet-only {
    @media (min-width: 768px) and (max-width: 1023px) {
      padding-left: 0;
    }
  }
  h2 {
    &:after {
      @media (min-width: 768px) {
        left: 0;
        right: auto;
      }
    }
    .sub {
      @media (min-width: 768px) and (max-width: 1023px) {
        font-size: 3vw !important;
      }
    }
  }
}

.top_regal {
  padding: 10% 0;
  background: url(/wp-content/uploads/bg_img002.jpg) no-repeat center / cover;
  @media (max-width: 767px) {
    background: url(/wp-content/uploads/sp_bg_img002.jpg);
  }
  h2 {
    &:after {
      @media (min-width: 768px) {
        left: 0;
        right: auto;
      }
    }
  }
}

.top_info {
  padding: 5% 0;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 50%;
    top: 0;
    bottom: auto;
    z-index: -1;
    background: url(/wp-content/uploads/bg_img003.jpg) no-repeat center top /
      100%;
    @media (max-width: 767px) {
      background: url(/wp-content/uploads/bg_img003.jpg) no-repeat center top;
    }
  }
  .news_inner {
    padding: 5%;
    background: #fff;
    margin: 0 -5%;
    @media (max-width: 767px) {
      margin: 0 auto;
    }
  }
  .tab_header {
    @media (min-width: 768px) {
      width: 32%;
    }
  }
  .tab {
    overflow: hidden;

    .tab__button {
      display: block;
      @media (max-width: 767px) {
        width: 50%;
        float: left;
      }
      p {
        a {
          display: inline-block;
          max-width: 290px;
          width: 100%;
          margin: 0;
          padding: 1.2em 12% 1.2em 54px;
          border-bottom: #e4e4f3 solid 2px;
          border-radius: 0px;
          line-height: 1;
          background: transparent;
          color: $color_text;
          text-align: left;
          text-decoration: none;
          appearance: none;
          transition: 0.3s;
          @include fontsize(16);
          cursor: pointer;
          position: relative;
          &:after {
            transform: none;
            content: "";
            display: block;
            position: absolute;
            border: none;
            width: 5px;
            height: 9px;
            top: 0;
            bottom: 0;
            left: auto;
            right: 8%;
            margin: auto;
            background: url(/wp-content/uploads/btn_arrow2.png) no-repeat center;
            transition: 0.3s;
          }
          &:hover {
            &:after {
              right: 6%;
            }
          }
          &:before {
            // 疑似要素
            content: "";
            display: block;
            position: absolute;
            width: 50px;
            height: 30px;
            top: 0;
            bottom: 0;
            right: auto;
            left: 3px;
            margin: auto;
          }

          &.media_btn {
            &:before {
              background: url(/wp-content/uploads/icon001.png);
            }
          }
          &.seminar_btn {
            &:before {
              background: url(/wp-content/uploads/icon002.png);
            }
          }
          &.book_btn {
            &:before {
              background: url(/wp-content/uploads/icon003.png);
            }
          }
          &.news_btn {
            &:before {
              background: url(/wp-content/uploads/icon004.png);
            }
          }
        }
      }

      &.active {
        p {
          a {
            border-bottom: 2px solid $color_primary;
          }
        }
      }
    }
  }
}

.top_lawer {
  padding: 10% 0;
  background: url(/wp-content/uploads/bg_img004.jpg) no-repeat center / cover;
  @media (min-width: 768px) and (max-width: 1023px) {
    background: url(/wp-content/uploads/bg_img004-1.jpg) no-repeat center /
      cover;
  }
  @media (max-width: 767px) {
    background: url(/wp-content/uploads/sp_bg_img004.jpg) no-repeat center /
      cover;
  }
  .name {
    font-size: 1.5em;
    font-weight: bold;
  }
  h2 {
    &:after {
      @media (min-width: 768px) {
        left: 0;
        right: auto;
      }
    }
  }
}

.top_access {
  padding: 6% 0;
  background: url(/wp-content/uploads/bg_img005.jpg) no-repeat center / cover;
  ul.access_data {
    padding: 0;
    li {
      list-style: none;
      padding: 0.5em 0;
      border-top: solid 1px #798db9;
      &:last-child {
        border-bottom: solid 1px #798db9;
      }
    }
  }
  .con_btn {
    a {
      background: $color_secondary;
      border-color: $color_secondary !important;
      max-width: 250px;
      &:hover {
        background: lighten($color_secondary, 5%);
      }
    }
  }
  .ac_logo {
    @media (max-width: 767px) {
      margin-top: 2em;
    }
  }
  .ac_btn_wrap {
    p {
      a {
        margin: 0 auto;
        text-align: left;
        padding: 1em 0 1em 1.2em;
        display: flex;
        align-items: center;
        height: 84px;
        @media (min-width: 768px) {
          max-width: 100%;
          @include fontsize(18);
        }
        @media (max-width: 767px) {
          max-width: 250px;
          @include fontsize(16);
        }
        > span {
          position: relative;
          padding-left: 45px;
          display: block;
          &:before {
            // 疑似要素
            content: "";
            display: block;
            position: absolute;
            width: 36px;
            height: 31px;
            top: 0;
            bottom: 0;
            right: auto;
            left: 0;
            margin: auto;
          }
        }
      }
      &.youtube_btn {
        a {
          background: #ff0000;
          border-color: #ff0000;
          > span {
            &:before {
              background: url(/wp-content/uploads/youtube_w.png);
            }
          }
          &:hover {
            background: lighten(#ff0000, 10%);
            border-color: lighten(#ff0000, 10%);
          }
        }
      }
      &.fb_btn {
        a {
          background: #1877f2;
          border-color: #1877f2;
          > span {
            &:before {
              background: url(/wp-content/uploads/facebook_w.png);
            }
          }
          &:hover{
            background: lighten(#1877f2, 5%);
            border-color: lighten(#1877f2, 5%);
          }
        }
      }
      &.more_btn {
        a {
          > span {
            &:before {
              background: url(/wp-content/uploads/access.png);
            }
          }
        }
      }
    }
  }
}
//*****************下層*****************//
.dib {
  display: inline-block;
}

.ul01 {
  padding-left: 1em;
  li {
    list-style: none;
    position: relative;
    margin-bottom: 0.3em;
    padding-left: 1em;
    box-sizing: border-box;
    &:before {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      background: $color_secondary;
      top: 6px;
      left: 0;
    }
  }
}
.ul02 {
  padding-left: 1em;

  margin-bottom: 1.5em;
  li {
    list-style: none;
    position: relative;
    margin-bottom: 0.3em;
    padding-left: 1em;
    box-sizing: border-box;
    &:before {
      position: absolute;
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background: $color_secondary;
      top: 8px;
      left: 0;
    }
  }
}
// ２列のリスト
@media (min-width: 768px) {
  .float-list {
    overflow: hidden;
    li {
      width: 50%;
      margin: 0 0 0.5em 0;
      padding-right: 0.8em;
      float: left;
    }
  }
  .float-list02 {
    overflow: hidden;
    li {
      width: 33%;
      margin: 0 0 0.5em 0;
      padding-right: 0.8em;
      float: left;
    }
  }
}
// リストの矢印
.ul_arrow {
  padding: 20px 20px 10px;
  box-sizing: border-box;
  margin: 0 auto 2em;
  list-style: none;
}
.ul_arrow li {
  position: relative;
  margin-bottom: 0.5em;
  padding-left: 1.2em;
}
.ul_arrow li:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 0.35em;
  left: 7px;
  margin: 0 0 0 0;
  border: 6px solid transparent;
  border-top: 8px solid $color_secondary;

  transform: rotate(-90deg);
}
.ul_arrow li a {
  text-decoration: none;
  color: $color_text;
}
.ul_arrow li a:hover {
  text-decoration: underline;
}

.table-custom {
  width: 100%;
  th {
    text-align: left;
  }
  td {
    text-align: right;
    @media (min-width: 768px) {
      width: 170px;
    }
    @media (max-width: 767px) {
      width: 135px;
    }
  }
}
