@charset "utf-8";
/*
 * moreボタン雛形 (hình dạng button more)
 */
.more {
  display: block;
  overflow: hidden;
  margin: auto;

  a {
    display: block;
    border: 1px solid $effect_more_color;
    color: $effect_arrow_color;
    background: $effect_more_color;
    padding: 0.6em;
    margin: 0 auto 1px;
    max-width: 280px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    transition: .3s;
    @include fontsize(14);
    @media ( min-width : 768px ) {
       @include fontsize(18);
    }
    &.inverse {
      border: 1px solid $effect_more_color;
      color: $effect_more_color;
      background: $effect_arrow_color;
    }
  }
}

