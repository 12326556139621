@charset "utf-8";
.article_list {
  padding: .5em;
  border-top: rgba($color_primary, 0.2) solid 1px;
  @media (min-width: 768px) {
    padding: 1em;
  }
  position: relative;
  overflow: hidden;

  .span_12 {
    margin: 2% 0;
  }

  &:last-of-type {
    border-bottom: rgba($color_primary, 0.2) solid 1px;
    margin-bottom: 6%;
  }

  .post_header {
    .time {
      &:before {
        content: '\f073';
        font-family: "Font Awesome 5 Free";
        margin-right: .5em;
      }
    }

    .category_badge {
      background: $color_primary;
      color: white;
      @include fontsize(14);
      margin-left: 1em;
      display: inline-block;

      a {
        display: block;
        padding: .2em 1em;
        color: white;
        text-decoration: none;
      }
    }
  }

  h2 {
    text-align: left;
    margin: 0 0 .5em;
    padding: 0;
    @include fontsize(24);
    @media (max-width: 767px) {
      @include fontsize(19);
    }

    &:after {
      content: none;
    }

    a {
      &:hover {
        text-decoration: none;
        color: $color_primary;
      }
    }
  }
}

.m-pagenation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  .m-pagenation__prev {
    margin-right: .5em;

    a {
      display: block;
      text-decoration: none;
      padding: .3em 1em;
      border: #ccc solid 1px;

      &:hover {
        background: rgba($color_primary, 0.1);
        border-color: rgba($color_primary, 0.1);
      }
    }
  }

  .m-pagenation__next {
    margin-left: .5em;

    a {
      display: block;
      text-decoration: none;
      padding: .3em 1em;
      border: #ccc solid 1px;
      &:hover{
        background: rgba($color_primary, 0.1);
        border-color: rgba($color_primary, 0.1);
      }
    }
  }

  .m-pagenation__body {
    @include listclear();
    display: flex;

    li {
      display: inline-block;
      margin: 0 5px;

      a {
        text-decoration: none;
        display: block;
        padding: .3em 1em;
        border: #ccc solid 1px;

        &:hover {
          background: rgba($color_primary, 0.1);
          //color: white;
          border-color: rgba($color_primary, 0.1);
        }
      }

      &.-current {
        background: $color_primary;
        color: white;
        border: $color_primary solid 1px;
        padding: .3em 1em;
      }
    }
  }
}

.article {
  .container {
    border-bottom: #ccc dotted 1px;
    padding-bottom: 3%;
  }
}
