@charset "utf-8";
// Q&Aパーツ(Q&A parts)
// _question_answer.scss に上書きして使うこと。 _cutom.scssには入れない。
.qa {


 


  &.qa_active {
 

    .answer {
      display: none;
    }
  }
}

.qa.qa-open {
  .question {
  

  .answer {
    display: block;
  }
}}