@charset "utf-8";

.article_single01 {
  .article_single01_02 {
    margin-top: 10px;

    img {
      opacity: 1;
      transition: opacity 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  .ofi {
    background: $color_highlight_bg;

  }

  .more {
    display: block;
    text-align: center;

    a {}
  }
}

.article_single02 {
  .article_single02_01 {
    img {
      opacity: 1;
      transition: opacity 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  .ofi {
    background: $color_highlight_bg;

  }

  .more {
    display: block;
    text-align: center;

    a {}
  }
}

.article_single03 {
  .article_single03_02 {
    .span_6 {
      @media ( min-width : 768px ) {
        width: 45%;
        margin-left: 10%;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .span_12 {
      @media ( min-width : 768px ) {
        margin-left: 0;
        margin-top: 20px;
      }
    }

    img {
      opacity: 1;
      transition: opacity 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }
  // case
  .case_arrow {
    position: relative;
    @media ( max-width : 767px ) {
      margin-bottom: 10%;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 108%;
      margin: auto;
      width: 35px;
      height: 35px;
      border-top: 3px solid $color_primary;
      border-right: 3px solid $color_primary;
      transform: translate(0, -50%) rotate(45deg);
      transform-origin: left top;
      transition: 0.2s;
      @media (max-width: 767px) {
        left: 0;
        right: 0;
        top: 105%;
        width: 20px;
        height: 20px;
        transform: translate(0, -50%) rotate(135deg);
        transform-origin: center;
      }
    }
  }

  .ofi {
    background: $color_highlight_bg;

  }

  .more {
    display: block;
    text-align: center;

    a {}
  }
}

.article_single04 {
  ul {
    @include listclear();
  }

  // 詳細
  .article_single04_01_img1 {
    .ofi {
      padding-top: 75%;
    }
  }

  .article_single04_01_img2 {
    .ofi {
      padding-top: 15%;
    }
  }

  .article_single04_01_img3 {
    .ofi {
      padding-top: 10.8%;
    }
  }

  .article_single04_01_img4 {
    .ofi {
      padding-top: 8.4%;
    }
  }

  .article_single04_01_img5 {
    .ofi {
      padding-top: 6.9%;
    }
  }

  .article_single04_01_img6 {
    .ofi {
      padding-top: 5.8%;
    }
  }

  .article_single04_01_nav {
    margin-top: 15px;

    .ofi {
      padding-top: 11.1%;
    }

    .slick-track {
      width: 100%!important;
      transform: none !important;
      left: 0 !important;
    }

    li {
      margin-left: 0.8%;
      width: calc(96% / 6)!important;

      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  // 一覧
  &.article_container {
    .article_single04_01_img1 {
      .ofi {
        padding-top: 74.5%;
      }
    }

    .article_single04_01_img2 {
      .ofi {
        padding-top: 18.7%;
      }
    }

    .article_single04_01_img3 {
      .ofi {
        padding-top: 14.9%;
      }
    }

    .article_single04_01_img4 {
      .ofi {
        padding-top: 12.5%;
      }
    }

    .article_single04_01_img5 {
      .ofi {
        padding-top: 10.7%;
      }
    }

    .article_single04_01_img6 {
      .ofi {
        padding-top: 9.4%;
      }
    }

    .article_single04_01_nav {
      margin-top: 15px;

      .ofi {
        padding-top: 12%;
      }

      .slick-track {
        width: 100%!important;
        transform: none !important;
        left: 0 !important;
      }

      li {
        margin-left: 0.8%;
        width: calc(96% / 6)!important;

        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  }

  .article_single04_02 {
    margin-top: 10px;

    img {
      opacity: 1;
      transition: opacity 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  .slick-next,
  .slick-prev {
    z-index: 1;

    &:before {
      content: none;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      margin: auto;
      width: 35px;
      height: 35px;
      border-top: 3px solid $color_primary;
      transition: 0.2s;
      z-index: 1;
    }
  }

  .slick-prev {
    left: 0;

    &:after {
      left: 0;
      border-left: 3px solid $color_primary;
      transform: translate(0, -50%) rotate(-45deg);
      transform-origin: right top;
    }
  }

  .slick-next {
    right: 0;

    &:after {
      right: 0;
      border-right: 3px solid $color_primary;
      transform: translate(0, -50%) rotate(45deg);
      transform-origin: left top;
    }
  }

  .ofi {
    background: $color_highlight_bg;

    height: auto!important;
  }

  .more {
    display: block;
    text-align: center;

    a {}
  }
}

.article_list_case02 {
  $val: 67;
  @media ( min-width : 768px ) {
    .article_list_case02_01 {
      width: #{$val}#{'%'};
    }

    .article_list_case02_02 {
      $val2: (98 - $val);
      width: #{$val2}#{'%'};
    }

    &.article_single01 {
      .article_single01_02 {
        margin-top: 0;
      }
    }
  }
}

.article_container {
  .article_single01_02 {
    margin-top: 10px;

    img {
      opacity: 1;
      transition: opacity 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }
}
