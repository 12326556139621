@charset "utf-8";

@media (max-width: 767px) {
  .sp_image_cols {
    &.sp_col2 { // 2カラム
      display: flex;
      flex-wrap: wrap;

      .col {
        width: 50%;
        padding: 0 1%;
      }
    }

    &.sp_col3 { // 3カラム
      display: flex;
      flex-wrap: wrap;

      .col {
        width: 33.3333333333%;
        padding: 0 1%;
      }
    }
  }
}
