@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&amp;subset=japanese");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css");
/* normalize */
@import url(vendor/common.css);
html {
  font-size: 62.5%;
  overflow-x: hidden;
}

html.scrollPrevent {
  overflow: hidden;
  height: 100%;
}

body {
  background: #fff;
  font-family: "Times New Roman", "Noto Serif JP", serif;
  font-size: 16px;
  font-size: 1.6em;
  line-height: 1.5;
  color: #333;
  height: auto !important;
  overflow: hidden;
}

@media (max-width: 767px) {
  body {
    font-size: 16px;
    font-size: 1.6em;
  }
}

main {
  overflow: hidden;
}

figure {
  margin: 0;
  text-align: center;
}

figure img {
  max-width: 100%;
  height: auto;
}

figure figcaption {
  margin-top: 0.5em;
  text-align: left;
}

a {
  color: #1d2088;
  transition: all .3s;
}

a:hover {
  color: #1d2088;
  text-decoration: none;
}

.highlight a {
  color: #333;
}

.highlight a:hover {
  color: #1d2088;
}

img {
  vertical-align: middle;
}

img.circle {
  border-radius: 50%;
}

p {
  margin: 0.5em 0 1em;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

ol,
ul {
  margin: 0 0 1em;
  padding: 0 0 0 2.5em;
  line-height: 1.6;
}

ol:last-child,
ul:last-child {
  margin-bottom: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0.5em 0;
  padding-left: 2em;
}

ol ol:last-child,
ol ul:last-child,
ul ol:last-child,
ul ul:last-child {
  margin-bottom: 0.5em;
}

ul.list-unstyled {
  padding-left: 0;
  list-style: none;
}

ul.list-unstyled ol,
ul.list-unstyled ul {
  padding-left: 2em;
}

ul.list-inline {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: -7px;
  font-size: 0px;
  font-size: 0rem;
  margin-bottom: 16px;
}

ul.list-inline > li {
  display: inline;
  padding-left: 7px;
  padding-right: 7px;
  white-space: nowrap;
  font-size: 16px;
  font-size: 1.6rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

main section {
  margin-bottom: 10%;
}

main section.highlight:last-child {
  margin-bottom: 0;
  padding-bottom: 10%;
}

@media (min-width: 768px) {
  p {
    margin: 0.5em 0 1em;
  }
  main section {
    margin-bottom: 5%;
  }
  main section:last-child {
    margin-bottom: 7%;
  }
}

@media (min-width: 768px) {
  .gutters .span_9.column_main {
    width: 70.83%;
  }
  .gutters .span_3.column_sub {
    width: 25%;
  }
}

@media (max-width: 900px) {
  .gutters .span_3.column_sub,
  .gutters .span_9.column_main {
    float: none;
    width: 100%;
    margin-left: 0;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .gutters .span_3.column_sub .sidebar {
    letter-spacing: -.40em;
  }
  .gutters .span_3.column_sub .sidebar .widget_wrap {
    letter-spacing: normal;
    display: inline-block;
    vertical-align: top;
    width: 49%;
    margin-left: 2%;
  }
  .gutters .span_3.column_sub .sidebar .widget_wrap:nth-child(2n+1) {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 1000px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

.article_button {
  margin-top: 5%;
}

@media (min-width: 768px) {
  .article_detail:not(.reverse) .col.span_12 + .span_4,
  .article_list:not(.reverse) .col.span_12 + .span_4 {
    margin-left: 0;
  }
}

.sidebar {
  visibility: hidden;
}

.sidebar > .widget_wrap {
  visibility: visible;
}

.sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 6%;
}

.sidebar ul a {
  color: #333;
  text-decoration: none;
  display: block;
  padding: .5em;
  border-bottom: rgba(29, 32, 136, 0.1) solid 1px;
}

.sidebar ul a:hover {
  color: #1d2088;
  background: rgba(29, 32, 136, 0.05);
}

.gutters .col.span_12 + .span_12 {
  margin-left: 0;
}

.map_wide #map_canvas,
.map_wide iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map_wide img {
  max-width: none;
}

.slick-track {
  margin: 0 auto;
}

.col dl p {
  line-height: 1.4;
  margin: 0;
}

.col p {
  line-height: 1.8;
}

.container {
  padding: 0 10px;
}

@media (min-width: 1020px) {
  .container {
    padding: 0;
  }
}

.container .container {
  padding: 0;
}

.highlight {
  padding: 4% 0;
  background: rgba(29, 32, 136, 0.1);
  color: #333;
}

.highlight.round {
  border-radius: 10px;
}

p.highlight, .col.highlight {
  padding: 4%;
}

.globalHeader {
  background: #fff;
  color: #333;
  font-size: 14px;
}

@media (max-width: 1479px) {
  .globalHeader.high {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  }
}

@media (max-width: 767px) {
  .globalHeader {
    background: #fff;
    position: fixed;
    z-index: 999;
    width: 100%;
  }
}

.globalHeader .container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;
  height: 120px;
  max-width: 100%;
}

.globalHeader .container .globalHeader_top {
  width: 98%;
  margin: 0 auto;
  padding-right: 180px;
}

@media (max-width: 1479px) {
  .globalHeader .container {
    height: 80px;
  }
}

.globalHeader .globalHeader_top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.globalHeader .header_logo {
  width: 530px;
  height: 36px;
  margin: 0;
}

@media (min-width: 768px) and (max-width: 1479px) {
  .globalHeader .header_logo {
    width: 500px;
    height: auto;
  }
}

@media (max-width: 1479px) {
  .globalHeader .header_logo {
    width: 200px;
    height: auto;
  }
}

.globalHeader .header_logo a {
  display: block;
  height: auto;
  line-height: 0;
}

.globalHeader .header_logo a img {
  max-width: 100%;
}

.global_nav {
  width: auto;
  list-style-position: inside;
}

@media (max-width: 1479px) {
  .global_nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 56;
    transition: all 0.3s;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.global_nav > ul {
  list-style: none;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

@media (min-width: 1480px) {
  .global_nav > ul {
    margin-top: 65px;
  }
}

@media (max-width: 1479px) {
  .global_nav > ul {
    display: block;
    background: rgba(29, 32, 136, 0.9);
    width: 80%;
    transform: translateX(100%);
    transition: all 0.3s;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1479px) {
  .global_nav > ul li {
    border-bottom: #b3b3b3 solid 1px;
  }
  .global_nav > ul li:first-child {
    border-top: #b3b3b3 solid 1px;
  }
}

.global_nav > ul li a {
  color: #333;
  text-decoration: none;
  padding: 0 1em;
  transition: all 0.3s;
}

@media (min-width: 1480px) and (max-width: 1600px) {
  .global_nav > ul li a {
    padding: 0 0.3em;
  }
}

@media (min-width: 1480px) {
  .global_nav > ul li a {
    position: relative;
  }
}

@media (max-width: 1479px) {
  .global_nav > ul li a {
    display: block;
    padding: 1em;
    color: #fff;
    position: relative;
  }
  .global_nav > ul li a:after {
    content: "";
    width: 8px;
    height: 8px;
    border: 0;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    right: 1em;
    margin-top: -4px;
  }
}

.global_nav > ul li a:hover {
  opacity: 0.8;
}

.global_nav > ul li:first-child a:before {
  content: none;
}

@media (max-width: 1479px) {
  .global_nav.open {
    opacity: 1;
    visibility: visible;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
  }
}

@media (max-width: 1479px) {
  .global_nav.open > ul {
    transform: translateX(0);
  }
}

/**ハンバーガーメニュー**/
#headerToggle {
  display: none;
}

@media (max-width: 1479px) {
  #headerToggle {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 20px;
    z-index: 99;
    cursor: pointer;
    width: 40px;
    height: 40px;
    transition: all 0.3s;
  }
  #headerToggle span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: #1d2088;
    transition: all 0.4s;
  }
  #headerToggle span:nth-of-type(1) {
    top: 0;
  }
  #headerToggle span:nth-of-type(2) {
    top: 17px;
  }
  #headerToggle span:nth-of-type(3) {
    bottom: 0;
  }
  #headerToggle.active {
    right: calc(80% + 20px);
  }
  #headerToggle.active span {
    background-color: white;
  }
  #headerToggle.active span:nth-of-type(1) {
    transform: translateY(16px) rotate(-45deg);
  }
  #headerToggle.active span:nth-of-type(2) {
    left: 50%;
    opacity: 0;
  }
  #headerToggle.active span:nth-of-type(3) {
    transform: translateY(-17px) rotate(45deg);
  }
}

.hdr_info_wrapper {
  display: none;
}

@media (min-width: 1480px) {
  .hdr_info_wrapper {
    display: block;
    position: absolute;
    top: 15px;
    right: 180px;
  }
}

.hdr_info_wrapper .hdr_info {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.hdr_info_wrapper .hdr_info_group {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hdr_info_wrapper .hdr_info_group li {
  margin-left: 10px;
}

.hdr_info_wrapper .hdr_info_group .button, .hdr_info_wrapper .hdr_info_group .inquiry .form_button, .inquiry .hdr_info_wrapper .hdr_info_group .form_button {
  min-width: 140px;
  font-size: 14px;
  font-size: 1.4rem;
  background: transparent;
  color: #1d2088;
}

.hdr_info_wrapper .hdr_info_group .button:after, .hdr_info_wrapper .hdr_info_group .inquiry .form_button:after, .inquiry .hdr_info_wrapper .hdr_info_group .form_button:after {
  transform: none;
  content: "";
  display: block;
  position: absolute;
  border: none;
  width: 5px;
  height: 9px;
  top: 0;
  bottom: 0;
  right: auto;
  left: 10%;
  margin: auto;
  background: url(/wp-content/uploads/btn_arrow2.png) no-repeat center;
}

.hdr_info_wrapper .hdr_info_group .button:hover, .hdr_info_wrapper .hdr_info_group .inquiry .form_button:hover, .inquiry .hdr_info_wrapper .hdr_info_group .form_button:hover {
  background: rgba(29, 32, 136, 0.1);
}

.hdr_info_wrapper .hdr_info_group .button:hover:after, .hdr_info_wrapper .hdr_info_group .inquiry .form_button:hover:after, .inquiry .hdr_info_wrapper .hdr_info_group .form_button:hover:after {
  left: 11%;
  right: auto;
}

.sp_hdr_info_wrapper {
  display: none;
}

@media (max-width: 1479px) {
  .sp_hdr_info_wrapper {
    display: block;
    position: absolute;
    top: 13px;
    right: 75px;
  }
}

@media (max-width: 480px) {
  .sp_hdr_info_wrapper {
    top: 20px;
  }
}

.sp_hdr_info_wrapper .sp_hdr_info_group {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li {
  margin-left: 10px;
}

@media (max-width: 480px) {
  .sp_hdr_info_wrapper .sp_hdr_info_group li img {
    width: 40px;
  }
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_tel {
  color: white;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_tel a {
  color: #1d2088;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_tel i {
  font-size: 32px;
  font-size: 3.2rem;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_mail {
  color: white;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_mail a {
  color: #1d2088;
}

.sp_hdr_info_wrapper .sp_hdr_info_group li.hdr_icon_mail i {
  font-size: 32px;
  font-size: 3.2rem;
}

.hdr_contact {
  position: absolute;
  right: 0;
  left: auto;
  display: block;
  top: 0;
  bottom: auto;
  margin: 0;
}

.hdr_contact p {
  width: 180px;
  height: 120px;
  position: relative;
}

.hdr_contact p:after {
  content: "";
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  top: auto;
  bottom: 10px;
  right: 10px;
  left: auto;
  margin: auto;
  background: url(/wp-content/uploads/btn_arrow.png) no-repeat center;
}

.hdr_contact p a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  width: 100%;
  height: 100%;
  background: #f38f00;
}

.hdr_contact p a:hover {
  background: #ff9c0e;
}

footer {
  position: relative;
  padding: 60px 0;
  font-size: 14px;
}

footer .fNav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

footer .fNav ul li + li {
  border-top: 1px solid rgba(29, 32, 136, 0.3);
}

footer .fNav ul li a {
  display: block;
  padding: 1em 15px;
  text-decoration: none;
}

footer .fNav ul li.has_under ul {
  display: block !important;
}

footer .copy_right {
  font-size: 10px;
  font-size: 1rem;
}

footer .fNav {
  margin-bottom: 5%;
}

footer .fNav ul {
  width: 100%;
  text-align: left;
  border-bottom: none;
}

footer .fNav ul li {
  border: none !important;
}

footer .fNav ul li a {
  padding: 0.2em 1em 0.2em 1em !important;
  text-decoration: none;
  position: relative;
}

footer .fNav ul li a:before {
  width: 8px;
  height: 8px;
  border: none;
  background: #f38f00;
  content: "";
  position: absolute;
  top: 33%;
  left: 0;
}

footer .fNav ul li > ul {
  padding-left: 0.2em;
}

footer .fNav ul li > ul a {
  font-size: 90%;
}

footer .fNav ul li > ul a:before {
  width: 5px;
  height: 5px;
  border: none;
  background: #f38f00;
  border-radius: 50%;
  margin-top: -2px;
}

.copy_right {
  background: #15164d;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  margin: 0;
  padding: 2em 0;
  border-top: solid 1px #323262;
}

@media (max-width: 767px) {
  .copy_right {
    padding: 1em 0;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  footer nav ul li.tablet-break:after {
    content: "\A";
    white-space: pre;
  }
  footer nav ul li.tablet-break + li:before {
    display: none;
  }
}

footer {
  background: #15164d;
  color: #fff;
}

footer a {
  color: #fff;
}

footer a:hover {
  color: #fff;
}

@media (min-width: 768px) {
  footer .container .row nav ul {
    display: inline-block;
  }
  footer .container .row nav a {
    display: inline-block;
    padding: 0.3em;
  }
}

.slider-pro.contents {
  overflow: hidden;
  margin: 0 auto !important;
}

.slider-pro.contents .sp-caption-container {
  width: 90%;
  margin: 0 auto;
  padding: 10px;
}

.slider-pro.contents .sp-caption-container h3 {
  margin: 0 0 15px;
  font-size: 14px;
  font-size: 1.4rem;
}

.slider-pro.contents .sp-caption-container h3:last-child {
  margin-bottom: 0;
}

.slider-pro.contents .sp-caption-container p {
  font-size: 13px;
  font-size: 1.3rem;
}

.slider-pro.contents.sp-horizontal .sp-previous-arrow {
  left: 10px;
  margin-top: 180px;
}

.slider-pro.contents.sp-horizontal .sp-next-arrow {
  right: 10px;
  margin-top: 180px;
}

.slider-pro.contents .sp-next-arrow:after,
.slider-pro.contents .sp-next-arrow:before,
.slider-pro.contents .sp-previous-arrow:after,
.slider-pro.contents .sp-previous-arrow:before {
  background-color: #1d2088;
}

@media (max-width: 767px) {
  .slider-pro.contents .sp-arrow {
    width: 10px;
    height: 15px;
  }
}

@media (min-width: 768px) {
  .slider-pro.contents .sp-caption-container {
    width: 450px;
    min-height: 40px;
    margin: 10px auto 0;
    border-right: 1px dashed #999;
    border-left: 1px dashed #999;
  }
  .slider-pro.contents .sp-arrow {
    width: 10px;
    height: 15px;
  }
  .slider-pro.contents.sp-horizontal .sp-previous-arrow {
    left: 50%;
    margin-top: 187.5px;
    margin-left: -230px;
  }
  .slider-pro.contents.sp-horizontal .sp-next-arrow {
    right: 50%;
    margin-top: 187.5px;
    margin-right: -230px;
  }
}

.slider-pro.center {
  overflow: hidden;
  width: 100%;
}

.slider-pro.center .sp-button {
  border: none;
  background: #d2d2d2;
}

.slider-pro.center .sp-selected-button {
  background: #1d2088;
}

.slider-pro.center.sp-horizontal .sp-arrows {
  left: 0;
  right: 0;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.slider-pro.center.sp-horizontal .sp-previous-arrow {
  left: 10px;
}

.slider-pro.center.sp-horizontal .sp-next-arrow {
  right: 10px;
}

.slider-pro.center .sp-previous-arrow,
.slider-pro.center .sp-next-arrow {
  width: 30px;
  height: 30px;
  background-color: rgba(29, 32, 136, 0.7);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 8px;
}

.slider-pro.center .sp-previous-arrow {
  background-image: url(/wp-content/uploads/arrow_left_wh.png);
}

.slider-pro.center .sp-next-arrow {
  background-image: url(/wp-content/uploads/arrow_right_wh.png);
}

.slider-pro.center .sp-next-arrow:after,
.slider-pro.center .sp-next-arrow:before,
.slider-pro.center .sp-previous-arrow:after,
.slider-pro.center .sp-previous-arrow:before {
  display: none;
}

.slider-pro.center .sp-layer {
  line-height: 1.2;
  white-space: normal !important;
  font-size: 20px;
  font-size: 2rem;
  color: #333;
}

.slider-pro.center .sp-layer small {
  font-size: 14px;
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .slider-pro.center .sp-layer {
    font-size: 22px;
    font-size: 2.2rem;
  }
  .slider-pro.center .sp-padding {
    padding: 35px;
  }
}

@media (min-width: 1100px) {
  .slider-pro.center.sp-horizontal .sp-previous-arrow {
    left: -15px;
  }
  .slider-pro.center.sp-horizontal .sp-next-arrow {
    right: -15px;
  }
}

.slider-pro.wide {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto 10px;
}

.slider-pro.wide .sp-buttons {
  position: absolute;
  z-index: 2;
  bottom: 10px;
  right: 10px;
  width: auto;
  padding-top: 0;
  text-align: right;
}

.slider-pro.wide .sp-button {
  border: none;
  background: #d2d2d2;
}

.slider-pro.wide .sp-selected-button {
  background: #1d2088;
}

.slider-pro.wide .sp-previous-arrow,
.slider-pro.wide .sp-next-arrow {
  z-index: 2;
}

.slider-pro.wide .sp-next-arrow:after,
.slider-pro.wide .sp-next-arrow:before,
.slider-pro.wide .sp-previous-arrow:after,
.slider-pro.wide .sp-previous-arrow:before {
  color: #fff;
}

.slider-pro.wide .hps-slider-mask {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider-pro.wide .hps-slider-mask.stripe {
  background-color: rgba(255, 255, 255, 0.2);
  background-image: url(/wp-content/uploads/mask_stripe.png);
}

.slider-pro.wide .hps-slider-copy {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 50px;
  color: #333;
  text-align: left;
  font-size: 12px;
  font-size: 1.2rem;
}

.slider-pro.wide .hps-slider-copy .lead {
  margin: 0;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1;
  text-align: center;
  color: #333;
}

.slider-pro.wide.disable-arrows .hps-slider-copy {
  padding: 0 10px;
}

.slider-pro.wide .hps-slider-scroll {
  display: none;
}

@media (max-width: 767px) {
  .slider-pro.wide.sp-disable-arrows .hps-slider-copy {
    padding: 0 10px;
  }
}

@media (min-width: 768px) {
  .slider-pro.wide .sp-buttons {
    bottom: 20px;
    right: 25px;
  }
  .slider-pro.wide .sp-button {
    width: 18px;
    height: 18px;
    margin: 0 10px;
  }
  .slider-pro.wide .hps-slider-copy {
    text-align: center;
    font-size: 17px;
    font-size: 1.7rem;
    line-height: 1.8;
  }
  .slider-pro.wide .hps-slider-copy .lead {
    font-size: 46px;
    font-size: 4.6rem;
  }
  .slider-pro.wide .hps-slider-scroll {
    display: block;
    position: absolute;
    left: 0;
    bottom: 15px;
    width: 100%;
    text-align: center;
  }
  .slider-pro.wide .hps-slider-scroll a {
    display: inline-block;
    width: 100px;
    padding-bottom: 45px;
    background: url(/wp-content/uploads/arrow_down_white.png) center bottom no-repeat;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-size: 1.2rem;
    text-decoration: none;
  }
  .slider-pro.wide .hps-slider-scroll.text-black a {
    background: url(/wp-content/uploads/arrow_down_black.png) center bottom no-repeat;
    color: #000;
  }
}

@media (min-width: 1024px) {
  .slider-pro.wide .hps-slider-scroll {
    display: block;
    bottom: 45px;
  }
}

.bread_wrap {
  margin-bottom: 2%;
}

.bread {
  overflow: hidden;
  margin: 0;
  font-size: 12px;
  font-size: 1.2rem;
}

.bread ul {
  padding: 1em 0;
  margin: 0 auto;
  float: none;
}

.bread ul li {
  list-style: none;
  position: relative;
  float: left;
  padding-right: 2em;
  margin-right: 1.5em;
}

.bread ul li a {
  text-decoration: none;
  color: #1d2088;
}

.bread ul li a:hover {
  color: #1d2088;
  text-decoration: none;
}

.bread ul li:after {
  content: '>';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.bread ul li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.bread ul li:last-child:after {
  content: none;
}

.button, .inquiry .form_button {
  display: inline-block;
  max-width: 200px;
  width: 100%;
  margin: 0;
  padding: 1em 1em;
  border: #1d2088 solid 1px;
  border-radius: 0px;
  line-height: 1.3;
  background: #1d2088;
  color: white;
  text-align: center;
  text-decoration: none;
  appearance: none;
  transition: 0.3s;
  font-size: 14px;
  font-size: 1.4rem;
  cursor: pointer;
  /*
* ボタン矢印
*/
}

.button:hover, .inquiry .form_button:hover {
  background: #262ab2;
  color: white;
  border-color: #262ab2;
}

.button.arrow, .inquiry .arrow.form_button {
  position: relative;
  font-size: 14px;
  font-size: 1.4rem;
}

.button.arrow:after, .inquiry .arrow.form_button:after {
  content: "";
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  top: auto;
  bottom: 10px;
  right: 10px;
  left: auto;
  margin: auto;
  background: url(/wp-content/uploads/btn_arrow.png) no-repeat center;
  transition: 0.2s;
}

.button.arrow:hover, .inquiry .arrow.form_button:hover {
  border: 1px solid #1d2088;
}

.button.arrow.external:after, .inquiry .arrow.external.form_button:after {
  width: 15px;
  height: 15px;
  bottom: 40%;
  background: url(/wp-content/uploads/external.png);
}

.highlight .button, .highlight .inquiry .form_button, .inquiry .highlight .form_button {
  color: white;
}

.highlight .button:hover, .highlight .inquiry .form_button:hover, .inquiry .highlight .form_button:hover {
  color: white;
}

.button.block, .inquiry .block.form_button {
  display: block;
  min-width: 0;
}

.button.large, .inquiry .large.form_button {
  padding: 1em 2em;
  font-size: 16px;
  font-size: 1.6rem;
}

.button.small, .inquiry .small.form_button {
  padding: 0.6em 1em;
  max-width: unset;
  width: auto;
}

.button.small.arrow, .inquiry .small.arrow.form_button {
  padding-right: 2.5em;
}

.button + .button, .inquiry .form_button + .button, .inquiry .button + .form_button, .inquiry .form_button + .form_button {
  margin-left: 0.5em;
}

/* お問い合わせフォーム */
.inquiry fieldset {
  margin: 0;
  padding: 4%;
  border: rgba(29, 32, 136, 0.1) solid 5px;
  border-radius: 10px;
}

.inquiry .table-default {
  border: none;
}

.inquiry .table-default th {
  background: none;
  border: none;
  text-align: left;
  padding: .5em;
  vertical-align: top;
  font-weight: bold;
}

.inquiry .table-default td {
  background: none;
  border: none;
  padding: .5em;
}

.inquiry input[type="text"],
.inquiry input[type="url"],
.inquiry input[type="email"],
.inquiry input[type="tel"],
.inquiry input[type="date"],
.inquiry input[type="number"],
.inquiry select,
.inquiry textarea {
  width: 100%;
  padding: 5px;
  color: #000;
  font-size: 16px;
  font-size: 1.6rem;
  margin: .2em .2em;
}

.inquiry input[type="number"] {
  width: 65px;
  text-align: center;
}

.inquiry input.p-postal-code {
  width: 100px;
}

.inquiry input.p-region {
  width: 100px;
}

.inquiry input.p-locality {
  width: 150px;
}

.inquiry input.p-street-address {
  width: 100%;
}

.inquiry input.p-extended-address {
  width: 100%;
}

.inquiry .mwform-tel-field {
  display: flex;
  justify-content: space-between;
}

.inquiry .submit {
  margin: 1em 0;
  text-align: center;
}

.inquiry .required {
  display: inline-block;
  background: #c00;
  color: white;
  padding: .2em .5em;
  font-size: 12px;
  font-size: 1.2rem;
  margin-left: .5em;
}

@media (min-width: 768px) {
  .inquiry input[type="text"],
  .inquiry input[type="url"],
  .inquiry input[type="email"],
  .inquiry input[type="tel"],
  .inquiry input[type="date"],
  .inquiry input[type="text"],
  .inquiry select {
    margin: 0 .2em;
  }
  .inquiry input[type="number"] {
    width: 65px;
  }
  .inquiry input.p-postal-code {
    width: 100px;
  }
  .inquiry input.p-region {
    width: 100px;
  }
  .inquiry input.p-locality {
    width: 150px;
  }
  .inquiry input.p-street-address {
    width: 100%;
  }
  .inquiry input.p-extended-address {
    width: 100%;
  }
  .inquiry textarea {
    width: 100%;
  }
  .inquiry .input_wrap {
    display: block;
    margin: 0 .2em;
  }
  .inquiry .input_wrap input, .inquiry .input_wrap textarea {
    margin: 0;
  }
  .inquiry .input_wrap:last-child {
    margin-right: auto;
  }
  .inquiry .submit {
    margin: 60px 0 0;
  }
}

.form_button_wrap {
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .form_button_wrap {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}

.form_button_wrap button {
  margin: .5em !important;
}

.privacy_wrap {
  margin-top: 5%;
  border: #ccc solid 1px;
  padding: 1em;
  height: 400px;
  overflow-y: scroll;
}

.mw_wp_form_confirm .input-show {
  display: none;
}

.mw_wp_form_input .input-hide {
  display: none;
}

.movie {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
}

.movie iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.movie.ar16to9 {
  padding-top: 56.25%;
}

.movie.ar4to3 {
  padding-top: 75%;
}

@media (max-width: 767px) {
  .news .heading {
    display: flex;
    justify-content: space-between;
  }
  .news .heading h2 {
    text-align: left;
    margin: 0 0 1em;
  }
  .news .heading h2:after {
    right: auto;
  }
}

@media (max-width: 767px) {
  .news .content {
    margin-top: 5%;
  }
}

.news dl {
  margin: 0;
  overflow-y: auto;
  max-height: 300px;
}

.news dl dt {
  margin: 0.5em 0 0;
  padding: 0 1em;
  font-weight: normal;
}

.news dl dd {
  margin: 0.5em 0;
  padding: 0 1em 0.5em;
  border-bottom: #ccc solid 1px;
  position: relative;
}

.news dl dd a {
  text-decoration: none;
}

.news dl dd a:hover {
  text-decoration: underline;
}

.news dl .badge_category {
  display: inline-block;
  background: #1d2088;
  color: white;
  padding: 0.2em 1em;
  margin-left: 1em;
  font-size: 14px;
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .news > div {
    display: flex;
    margin: 0 auto;
  }
  .news > div .heading {
    width: 23.5%;
  }
  .news h2 {
    margin: 0;
    padding: 0;
  }
  .news h2:after {
    display: none;
  }
  .news .content {
    overflow: hidden;
    width: 64%;
    padding-left: 2%;
  }
  .news dl {
    overflow-y: auto;
    max-height: 200px;
  }
  .news dl dt {
    margin: 0;
    padding: 1em 0 0.5em 0;
  }
  .news dl dd {
    margin: 0;
    padding: 0.5em 0 1em 0;
  }
}

.shopinfo figure {
  max-width: 80%;
  margin: 0 auto;
}

.shopinfo address {
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
  font-style: normal;
}

.shopinfo dl {
  margin: 2em 0;
  line-height: 1.4;
}

.shopinfo dl:first-child {
  margin-top: 0;
}

.shopinfo dl dt {
  margin: 0 0 0.2em 0;
  font-weight: normal;
}

.shopinfo dl dt:before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 3px;
  margin-right: 0.3em;
  vertical-align: middle;
  background: #1d2088;
}

.shopinfo dl dd {
  margin: 0 0 0.5em 1em;
  padding: 0;
}

.map_container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 70%;
}

.map_container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map_container + p {
  margin-top: 2em;
}

.map_wide {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 70%;
}

.map_wide iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.scroll .googlemap {
  pointer-events: none;
}

@media (min-width: 768px) {
  .shopinfo figure {
    max-width: 100%;
  }
  .shopinfo address {
    margin-bottom: 15px;
    text-align: left;
    font-size: 26px;
    font-size: 2.6rem;
    line-height: 1;
  }
  .shopinfo dl.dl-horizontal {
    margin: 10px 0;
  }
  .shopinfo dl.dl-horizontal dd {
    margin-bottom: 0.3em;
  }
  .shopinfo .span12 .map_container {
    padding-top: 50%;
  }
  .map_wide {
    padding-top: 420px;
  }
}

#sitemap {
  border-top: rgba(51, 51, 51, 0.3) solid 1px;
}

#sitemap ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#sitemap ul li a {
  display: block;
  text-decoration: none;
  position: relative;
  padding: .5em 1em;
  border-bottom: rgba(51, 51, 51, 0.3) solid 1px;
  font-size: 18px;
  font-size: 1.8rem;
}

#sitemap ul li a:before {
  content: '';
  width: 6px;
  height: 6px;
  border: 0px;
  border-top: solid 2px #333;
  border-right: solid 2px #333;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

#sitemap ul li a:hover {
  background: rgba(29, 32, 136, 0.1);
}

.sns_icons {
  display: table;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0px;
  font-size: 0rem;
  margin: auto;
}

.sns_icons > li {
  display: inline;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
  font-size: 16px;
  font-size: 1.6rem;
}

.sns_icons li {
  display: inline-block;
}

.sns_icons li:not(:last-child) {
  margin-right: 10px;
}

.sns_icons a {
  display: block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 34px;
  height: 34px;
  background-repeat: no-repeat;
  background-size: contain;
}

.sns_icons a.icon_facebook {
  background-image: url(/wp-content/uploads/icon_facebook.png);
}

.sns_icons a.icon_twitter {
  background-image: url(/wp-content/uploads/icon_twitter.png);
}

.sns_icons a.icon_line {
  background-image: url(/wp-content/uploads/icon_line.png);
}

.sns_icons a.icon_google {
  background-image: url(/wp-content/uploads/icon_google.png);
}

.sns_icons a.icon_instagram {
  background-image: url(/wp-content/uploads/icon_instagram.png);
}

footer .sns_icons {
  margin-top: 1.5em;
}

@media (min-width: 768px) {
  .sns_icons {
    margin: 0;
  }
  .sns_icons.center {
    margin: auto;
  }
  .sns_icons li:not(:last-child) {
    margin-right: 20px;
  }
  .sns_icons a {
    width: 68px;
    height: 68px;
  }
  footer .sns_icons {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .sp_image_cols.sp_col2 {
    display: flex;
    flex-wrap: wrap;
  }
  .sp_image_cols.sp_col2 .col {
    width: 50%;
    padding: 0 1%;
  }
  .sp_image_cols.sp_col3 {
    display: flex;
    flex-wrap: wrap;
  }
  .sp_image_cols.sp_col3 .col {
    width: 33.3333333333%;
    padding: 0 1%;
  }
}

table {
  margin: 0 auto;
}

table td,
table th {
  padding: 0.7em 1em;
}

@media (max-width: 767px) {
  table td,
  table th {
    padding: 0.7em .3em;
  }
}

table th {
  text-align: center;
  font-weight: normal;
}

.table-default {
  width: 100%;
}

.table-default thead th, .table-default thead td {
  background: rgba(29, 32, 136, 0.15);
}

.table-default td,
.table-default th {
  border: 1px solid #ccc;
}

.table-default th {
  background: rgba(204, 204, 204, 0.2);
  color: #333;
}

.table-default td {
  background: transparent;
}

.table-menu {
  width: 100%;
}

.table-menu caption {
  margin-bottom: 0.5em;
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
}

.table-menu td,
.table-menu th {
  padding: 0.7em 1em;
  border-top: 1px solid #1d2088;
  border-bottom: 1px solid #1d2088;
}

@media (max-width: 767px) {
  .table-menu td,
  .table-menu th {
    padding: 0.7em .3em;
  }
}

.table-menu tbody th {
  text-align: left;
}

.table-menu td:last-child {
  text-align: right;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .responsive-stack {
    display: block;
  }
  .responsive-stack thead {
    display: none;
  }
  .responsive-stack tbody,
  .responsive-stack td,
  .responsive-stack th,
  .responsive-stack tr {
    display: block;
  }
  .responsive-stack td,
  .responsive-stack th {
    width: 100% !important;
  }
  .responsive-stack tr:not(:last-child) td,
  .responsive-stack tr:not(:last-child) th {
    border-bottom: 0;
  }
  .responsive-stack tr:last-child :not(:last-child) {
    border-bottom: 0;
  }
}

@media (max-width: 767px) {
  .responsive-list {
    display: block;
  }
  .responsive-list thead {
    display: none;
  }
  .responsive-list tbody,
  .responsive-list td,
  .responsive-list th,
  .responsive-list tr {
    display: block;
  }
  .responsive-list tr:not(:last-child) td {
    border-bottom: 0;
  }
  .responsive-list td {
    position: relative;
    padding-left: 40%;
    white-space: normal;
    text-align: left;
  }
  .responsive-list td:first-child {
    border-bottom: 0;
  }
  .responsive-list td:last-child {
    border-top: 0;
  }
  .responsive-list td:not(:first-child):not(:last-child) {
    border-top: 0;
    border-bottom: 0;
  }
  .responsive-list td:before {
    content: attr(data-title);
    position: absolute;
    top: 6px;
    left: 6px;
    width: 35%;
    padding-right: 10px;
    white-space: normal;
    text-align: left;
    font-weight: bold;
  }
}

@media (max-width: 767px) {
  .responsive-scroll-container {
    display: block;
    width: 100%;
    min-height: 0.01%;
    overflow-x: scroll;
  }
  .responsive-scroll-container .responsive-scroll-inner {
    width: 798px;
    padding: 0 15px 15px;
  }
  .responsive-scroll-container table {
    width: 768px;
    margin: 0;
  }
}

table.table-default.typeA tr td,
table.table-default.typeA tr th {
  border: 0;
}

table.table-default.typeA tr:nth-child(odd) th {
  background: #1d2088;
}

table.table-default.typeA tr:nth-child(odd) td {
  background: rgba(29, 32, 136, 0.1);
}

table.table-default.typeA tr:nth-child(even) th {
  background: #262ab2;
}

table.table-default.typeA tr:nth-child(even) td {
  background: rgba(38, 42, 178, 0.1);
}

table.table-default.typeB {
  border-spacing: 0;
  overflow: hidden;
}

table.table-default.typeB tbody td,
table.table-default.typeB tbody th {
  background: transparent;
  color: #333;
  border: 0;
  border-bottom: 1px solid rgba(204, 204, 204, 0.1);
}

table.table-default.typeB tbody tr {
  position: relative;
}

table.table-default.typeB tbody th {
  color: #1d2088;
}

table.table-default.typeC th {
  background: rgba(204, 204, 204, 0.2);
  color: #333;
}

table.table-default.typeC td,
table.table-default.typeC th {
  border: 0;
}

table.table-default.typeC td:not(:last-child),
table.table-default.typeC th:not(:last-child) {
  border-bottom: 2px #fff solid;
}

table.table-default.typeC tr {
  position: relative;
}

table.table-default.typeC td {
  transform: translateX(2px);
}

.highlight table.table-default.typeC td,
.highlight table.table-default.typeC th {
  border: 0;
}

.highlight table.table-default.typeC td:not(:last-child),
.highlight table.table-default.typeC th:not(:last-child) {
  border-bottom: 2px rgba(29, 32, 136, 0.1) solid;
}

._table_line .news dl {
  border-top: 1px dotted #a6a6a6;
  max-height: none;
}

._table_line .news dl dt {
  width: 230px;
  padding-left: 20px;
}

._table_line .news dl dt + dd {
  border-bottom: 1px dotted #a6a6a6;
  padding-left: 230px;
}

.wide_image img {
  width: 100%;
  vertical-align: top;
}

@media (min-width: 768px) {
  .wide_image div {
    margin: 0 auto;
  }
}

h1:not(.header_logo) {
  text-align: center;
  font-size: 40px;
  font-size: 4rem;
  font-weight: normal;
  padding: .7em 5%;
  color: #fff;
  background: #1d2088;
  margin: 0 0 3%;
}

@media (max-width: 767px) {
  h1:not(.header_logo) {
    margin-top: 80px;
  }
}

h1:not(.header_logo) a {
  text-decoration: none;
}

h1:not(.header_logo) a:hover {
  text-decoration: underline;
}

h1:not(.header_logo) span {
  display: block;
  font-size: 16px;
  font-size: 1.6rem;
  padding-top: .5em;
  color: ligten(#fff, 10%);
  font-style: italic;
}

@media (max-width: 767px) {
  h1:not(.header_logo) {
    font-size: 24px;
    font-size: 2.4rem;
  }
  h1:not(.header_logo) span {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

h2 {
  display: block;
  margin: 0 auto .5em;
  position: relative;
  text-align: center;
  border: 0;
  font-weight: bold;
  padding: 0 0 .5em;
  font-size: 30px;
  font-size: 3rem;
  color: #333;
}

h2 a {
  text-decoration: none;
  color: #333;
}

h2 a:hover {
  text-decoration: underline;
  color: #333;
}

h2:after {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  width: 60px;
  right: 0;
  left: 0;
  bottom: 0;
  top: auto;
  margin: auto;
  background: #f38f00;
}

@media (max-width: 767px) {
  h2 {
    font-size: 24px;
    font-size: 2.4rem;
  }
  h2:after {
    width: 40px;
  }
}

h3 {
  margin: 0 0 .5em;
  padding-bottom: 0.5em;
  color: #333;
  font-size: 24px;
  font-size: 2.4rem;
  position: relative;
}

h3 a {
  text-decoration: none;
}

h3 a:hover {
  text-decoration: underline;
}

h3:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  background: rgba(243, 143, 0, 0.2);
}

@media (max-width: 767px) {
  h3 {
    font-size: 20px;
    font-size: 2rem;
  }
}

h4 {
  margin: 0 0 1em;
  padding-left: 1em;
  font-size: 20px;
  font-size: 2rem;
  position: relative;
  color: #333;
  font-weight: bold;
}

h4 a {
  text-decoration: none;
}

h4 a:hover {
  text-decoration: underline;
}

h4:after {
  content: '';
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 1em;
  margin: auto;
  background: #f38f00;
}

@media (max-width: 767px) {
  h4 {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.widget_wrap .widget_header {
  font-size: 16px;
  font-size: 1.6rem;
  color: #333;
  padding: .5em 0 .5em .5em;
  border-bottom: #f38f00 solid 2px;
  font-weight: bold;
}

.widget_wrap .widget_header a {
  text-decoration: none;
}

.widget_wrap .widget_header a:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .widget_wrap .widget_header {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.main_visual {
  position: relative;
}

@media (max-width: 767px) {
  .main_visual {
    margin-top: 80px;
  }
}

.main_visual .main_visual_slick,
.main_visual .slick-track {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100% !important;
}

.main_visual .slick-track {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.main_visual .slick-list {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
}

.main_visual .main_visual_slick {
  position: relative;
  margin: 0 auto;
}

.main_visual .main_visual_slick .slick-slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
}

.main_visual .main_visual_slick .slick-slide img {
  position: absolute;
  left: -300%;
  right: -300%;
  margin: auto;
  top: 0;
  height: 100%;
}

.main_visual .main_visual_copy {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  max-width: 100%;
  margin: 0 auto;
  z-index: 53;
}

.main_visual .main_visual_copy > li {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.main_visual .main_visual_copy > li img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 48.3%;
  max-width: 483px;
  max-height: 286px;
  z-index: 52;
  left: 2%;
  right: auto;
  width: 28vw;
}

@media (max-width: 767px) {
  .main_visual .main_visual_copy > li img {
    width: 92%;
    bottom: 4.5%;
    max-width: 645px;
    max-height: 283px;
    top: auto;
  }
}

.main_visual .main_visual_slick {
  padding-top: 71.9%!important;
}

.main_visual .main_visual_slick {
  padding-top: 37.4% !important;
}

@media (max-width: 767px) {
  .main_visual .main_visual_slick {
    padding-top: 114.66667%!important;
  }
}

.slick-dots {
  z-index: 55 !important;
  bottom: 0;
}

.slick-dots li button {
  margin: 0 auto;
  background: #ccc;
}

.slick-dots li.slick-active button {
  background: #1d2088;
}

.main_visual .main_visual_swiper .swiper-slide {
  position: relative;
}

.main_visual .main_visual_swiper .swiper-slide a {
  color: white;
}

.main_visual .main_visual_swiper .swiper-slide .ofi {
  padding-top: 700px;
}

.main_visual .main_visual_swiper .swiper-slide .text_wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  padding: 1em;
}

.main_visual .main_visual_swiper .swiper-slide .text_wrapper h3 {
  color: white;
  padding: 0;
  margin: 0 0 0.5em;
}

.main_visual .main_visual_swiper .swiper-slide .text_wrapper h3:after {
  content: none;
}

.main_visual .swiper-button-prev,
.main_visual .swiper-button-next {
  background: #1d2088;
  color: white;
  width: 60px;
  height: 60px;
}

@media (max-width: 767px) {
  .main_visual .swiper-button-prev,
  .main_visual .swiper-button-next {
    width: 40px;
    height: 40px;
  }
}

.main_visual .swiper-button-prev:after,
.main_visual .swiper-button-next:after {
  font-size: 16px;
}

@media (max-width: 767px) {
  .main_visual .swiper-button-prev:after,
  .main_visual .swiper-button-next:after {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .main_visual .swiper-button-prev {
    left: 0;
  }
}

@media (max-width: 767px) {
  .main_visual .swiper-button-next {
    right: 0;
  }
}

.main_visual .swiper-pagination {
  position: static;
  margin: 1em 0;
}

.main_visual .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #1d2088;
  margin: 0 5px;
  outline-color: transparent;
}

.article_list {
  padding: .5em;
  border-top: rgba(29, 32, 136, 0.2) solid 1px;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .article_list {
    padding: 1em;
  }
}

.article_list .span_12 {
  margin: 2% 0;
}

.article_list:last-of-type {
  border-bottom: rgba(29, 32, 136, 0.2) solid 1px;
  margin-bottom: 6%;
}

.article_list .post_header .time:before {
  content: '\f073';
  font-family: "Font Awesome 5 Free";
  margin-right: .5em;
}

.article_list .post_header .category_badge {
  background: #1d2088;
  color: white;
  font-size: 14px;
  font-size: 1.4rem;
  margin-left: 1em;
  display: inline-block;
}

.article_list .post_header .category_badge a {
  display: block;
  padding: .2em 1em;
  color: white;
  text-decoration: none;
}

.article_list h2 {
  text-align: left;
  margin: 0 0 .5em;
  padding: 0;
  font-size: 24px;
  font-size: 2.4rem;
}

@media (max-width: 767px) {
  .article_list h2 {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

.article_list h2:after {
  content: none;
}

.article_list h2 a:hover {
  text-decoration: none;
  color: #1d2088;
}

.m-pagenation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.m-pagenation .m-pagenation__prev {
  margin-right: .5em;
}

.m-pagenation .m-pagenation__prev a {
  display: block;
  text-decoration: none;
  padding: .3em 1em;
  border: #ccc solid 1px;
}

.m-pagenation .m-pagenation__prev a:hover {
  background: rgba(29, 32, 136, 0.1);
  border-color: rgba(29, 32, 136, 0.1);
}

.m-pagenation .m-pagenation__next {
  margin-left: .5em;
}

.m-pagenation .m-pagenation__next a {
  display: block;
  text-decoration: none;
  padding: .3em 1em;
  border: #ccc solid 1px;
}

.m-pagenation .m-pagenation__next a:hover {
  background: rgba(29, 32, 136, 0.1);
  border-color: rgba(29, 32, 136, 0.1);
}

.m-pagenation .m-pagenation__body {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.m-pagenation .m-pagenation__body li {
  display: inline-block;
  margin: 0 5px;
}

.m-pagenation .m-pagenation__body li a {
  text-decoration: none;
  display: block;
  padding: .3em 1em;
  border: #ccc solid 1px;
}

.m-pagenation .m-pagenation__body li a:hover {
  background: rgba(29, 32, 136, 0.1);
  border-color: rgba(29, 32, 136, 0.1);
}

.m-pagenation .m-pagenation__body li.-current {
  background: #1d2088;
  color: white;
  border: #1d2088 solid 1px;
  padding: .3em 1em;
}

.article .container {
  border-bottom: #ccc dotted 1px;
  padding-bottom: 3%;
}

.article_single01 .article_single01_02 {
  margin-top: 10px;
}

.article_single01 .article_single01_02 img {
  opacity: 1;
  transition: opacity 0.3s;
}

.article_single01 .article_single01_02 img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.article_single01 .ofi {
  background: rgba(29, 32, 136, 0.1);
}

.article_single01 .more {
  display: block;
  text-align: center;
}

.article_single02 .article_single02_01 img {
  opacity: 1;
  transition: opacity 0.3s;
}

.article_single02 .article_single02_01 img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.article_single02 .ofi {
  background: rgba(29, 32, 136, 0.1);
}

.article_single02 .more {
  display: block;
  text-align: center;
}

@media (min-width: 768px) {
  .article_single03 .article_single03_02 .span_6 {
    width: 45%;
    margin-left: 10%;
  }
  .article_single03 .article_single03_02 .span_6:first-child {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .article_single03 .article_single03_02 .span_12 {
    margin-left: 0;
    margin-top: 20px;
  }
}

.article_single03 .article_single03_02 img {
  opacity: 1;
  transition: opacity 0.3s;
}

.article_single03 .article_single03_02 img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.article_single03 .case_arrow {
  position: relative;
}

@media (max-width: 767px) {
  .article_single03 .case_arrow {
    margin-bottom: 10%;
  }
}

.article_single03 .case_arrow:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 108%;
  margin: auto;
  width: 35px;
  height: 35px;
  border-top: 3px solid #1d2088;
  border-right: 3px solid #1d2088;
  transform: translate(0, -50%) rotate(45deg);
  transform-origin: left top;
  transition: 0.2s;
}

@media (max-width: 767px) {
  .article_single03 .case_arrow:after {
    left: 0;
    right: 0;
    top: 105%;
    width: 20px;
    height: 20px;
    transform: translate(0, -50%) rotate(135deg);
    transform-origin: center;
  }
}

.article_single03 .ofi {
  background: rgba(29, 32, 136, 0.1);
}

.article_single03 .more {
  display: block;
  text-align: center;
}

.article_single04 ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.article_single04 .article_single04_01_img1 .ofi {
  padding-top: 75%;
}

.article_single04 .article_single04_01_img2 .ofi {
  padding-top: 15%;
}

.article_single04 .article_single04_01_img3 .ofi {
  padding-top: 10.8%;
}

.article_single04 .article_single04_01_img4 .ofi {
  padding-top: 8.4%;
}

.article_single04 .article_single04_01_img5 .ofi {
  padding-top: 6.9%;
}

.article_single04 .article_single04_01_img6 .ofi {
  padding-top: 5.8%;
}

.article_single04 .article_single04_01_nav {
  margin-top: 15px;
}

.article_single04 .article_single04_01_nav .ofi {
  padding-top: 11.1%;
}

.article_single04 .article_single04_01_nav .slick-track {
  width: 100% !important;
  transform: none !important;
  left: 0 !important;
}

.article_single04 .article_single04_01_nav li {
  margin-left: 0.8%;
  width: calc(96% / 6) !important;
}

.article_single04 .article_single04_01_nav li:nth-child(1) {
  margin-left: 0;
}

.article_single04.article_container .article_single04_01_img1 .ofi {
  padding-top: 74.5%;
}

.article_single04.article_container .article_single04_01_img2 .ofi {
  padding-top: 18.7%;
}

.article_single04.article_container .article_single04_01_img3 .ofi {
  padding-top: 14.9%;
}

.article_single04.article_container .article_single04_01_img4 .ofi {
  padding-top: 12.5%;
}

.article_single04.article_container .article_single04_01_img5 .ofi {
  padding-top: 10.7%;
}

.article_single04.article_container .article_single04_01_img6 .ofi {
  padding-top: 9.4%;
}

.article_single04.article_container .article_single04_01_nav {
  margin-top: 15px;
}

.article_single04.article_container .article_single04_01_nav .ofi {
  padding-top: 12%;
}

.article_single04.article_container .article_single04_01_nav .slick-track {
  width: 100% !important;
  transform: none !important;
  left: 0 !important;
}

.article_single04.article_container .article_single04_01_nav li {
  margin-left: 0.8%;
  width: calc(96% / 6) !important;
}

.article_single04.article_container .article_single04_01_nav li:nth-child(1) {
  margin-left: 0;
}

.article_single04 .article_single04_02 {
  margin-top: 10px;
}

.article_single04 .article_single04_02 img {
  opacity: 1;
  transition: opacity 0.3s;
}

.article_single04 .article_single04_02 img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.article_single04 .slick-next,
.article_single04 .slick-prev {
  z-index: 1;
}

.article_single04 .slick-next:before,
.article_single04 .slick-prev:before {
  content: none;
}

.article_single04 .slick-next:after,
.article_single04 .slick-prev:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  margin: auto;
  width: 35px;
  height: 35px;
  border-top: 3px solid #1d2088;
  transition: 0.2s;
  z-index: 1;
}

.article_single04 .slick-prev {
  left: 0;
}

.article_single04 .slick-prev:after {
  left: 0;
  border-left: 3px solid #1d2088;
  transform: translate(0, -50%) rotate(-45deg);
  transform-origin: right top;
}

.article_single04 .slick-next {
  right: 0;
}

.article_single04 .slick-next:after {
  right: 0;
  border-right: 3px solid #1d2088;
  transform: translate(0, -50%) rotate(45deg);
  transform-origin: left top;
}

.article_single04 .ofi {
  background: rgba(29, 32, 136, 0.1);
  height: auto !important;
}

.article_single04 .more {
  display: block;
  text-align: center;
}

@media (min-width: 768px) {
  .article_list_case02 .article_list_case02_01 {
    width: 67%;
  }
  .article_list_case02 .article_list_case02_02 {
    width: 31%;
  }
  .article_list_case02.article_single01 .article_single01_02 {
    margin-top: 0;
  }
}

.article_container .article_single01_02 {
  margin-top: 10px;
}

.article_container .article_single01_02 img {
  opacity: 1;
  transition: opacity 0.3s;
}

.article_container .article_single01_02 img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.article_detail {
  margin-bottom: 4%;
  font-size: 15px;
  font-size: 1.5rem;
}

.article_detail .article_date {
  margin-bottom: 1em;
}

.article_detail .article_thumbnail {
  float: left;
  margin: 0 2% 2% 0;
}

.article_detail .article_content {
  float: none;
  width: 100%;
  margin-left: 0;
}

.article_detail h2 {
  text-align: left;
  margin: 0 0 1em;
  padding: 0 0 .5em;
  font-size: 24px;
  font-size: 2.4rem;
  border-bottom: #1d2088 solid 2px;
}

@media (max-width: 767px) {
  .article_detail h2 {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

.article_detail h2:after {
  content: none;
}

.article_detail h2 a:hover {
  text-decoration: none;
  color: #1d2088;
}

.article_detail .case_arrow {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  display: block;
  position: relative;
  height: 245px;
}

@media (max-width: 767px) {
  .article_detail .case_arrow {
    height: 80px;
  }
}

.article_detail .case_arrow:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  margin: auto;
  width: 70px;
  height: 70px;
  border-top: 5px solid #1d2088;
  border-right: 5px solid #1d2088;
  transform: translate(0, -50%) rotate(45deg);
  transform-origin: left top;
  transition: .2s;
}

@media (max-width: 767px) {
  .article_detail .case_arrow:after {
    left: 0;
    bottom: 25%;
    border-top: 5px solid #1d2088;
    border-right: 5px solid #1d2088;
    transform: translate(0, -50%) rotate(135deg);
    transform-origin: center;
  }
}

.article_detail .span_12 {
  margin: 2% 0;
}

.article_detail .post_header .time:before {
  content: '\f073';
  font-family: "Font Awesome 5 Free";
  margin-right: .5em;
}

.article_detail .post_header .category_badge {
  background: #1d2088;
  color: white;
  font-size: 14px;
  font-size: 1.4rem;
  margin-left: 1em;
  display: inline-block;
}

.article_detail .post_header .category_badge a {
  display: block;
  padding: .2em 1em;
  color: white;
  text-decoration: none;
}

.pager {
  overflow: hidden;
  margin-bottom: 5%;
}

.pager .pager_prev {
  float: left;
}

.pager .pager_prev a {
  display: block;
  position: relative;
  padding-left: 15px;
  text-decoration: none;
}

.pager .pager_prev a:before, .pager .pager_prev a:after {
  content: "";
  display: block;
  position: absolute;
  left: 2px;
  margin: auto;
  width: 8px;
  height: 1px;
  background: #1d2088;
  transition: .2s;
}

.pager .pager_prev a:before {
  transform: translateY(-50%) rotate(-45deg);
  top: calc(50% - 2px);
}

.pager .pager_prev a:after {
  transform: translateY(-50%) rotate(-135deg);
  top: calc(50% + 3px);
}

.pager .pager_prev a:hover {
  text-decoration: underline;
}

.pager .pager_prev a:hover:before {
  left: 0;
}

.pager .pager_prev a:hover:after {
  left: 0;
}

.pager .pager_next {
  float: right;
}

.pager .pager_next a {
  display: block;
  position: relative;
  padding-right: 15px;
  text-decoration: none;
}

.pager .pager_next a:before, .pager .pager_next a:after {
  content: "";
  display: block;
  position: absolute;
  right: 2px;
  margin: auto;
  width: 8px;
  height: 1px;
  background: #1d2088;
  transform-origin: center;
  transition: .2s;
}

.pager .pager_next a:before {
  transform: translateY(-50%) rotate(45deg);
  top: calc(50% - 2px);
}

.pager .pager_next a:after {
  transform: translateY(-50%) rotate(135deg);
  top: calc(50% + 3px);
}

.pager .pager_next a:hover {
  text-decoration: underline;
}

.pager .pager_next a:hover:before {
  right: 0;
}

.pager .pager_next a:hover:after {
  right: 0;
}

/*
 * moreボタン雛形 (hình dạng button more)
 */
.more {
  display: block;
  overflow: hidden;
  margin: auto;
}

.more a {
  display: block;
  border: 1px solid #1d2088;
  color: #fff;
  background: #1d2088;
  padding: 0.6em;
  margin: 0 auto 1px;
  max-width: 280px;
  width: 100%;
  text-align: center;
  text-decoration: none;
  transition: .3s;
  font-size: 14px;
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .more a {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.more a.inverse {
  border: 1px solid #1d2088;
  color: #1d2088;
  background: #fff;
}

header .global_nav > ul > li.has_under > a {
  position: relative;
}

header .global_nav > ul > li.has_under > a:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 1em;
  bottom: 0;
  margin: auto;
  width: 7px;
  height: 7px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: translate(0, -50%) rotate(135deg);
  transition: .2s;
}

header .global_nav > ul > li.has_under > a.menu_under_open:after {
  transform: translate(0, 0) rotate(-45deg);
}

header .global_nav > ul > li > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
}

header .global_nav > ul > li > ul > li {
  border-bottom: 1px solid #b3b3b3;
}

header .global_nav > ul > li > ul > li:last-child {
  border-bottom: none;
}

header .global_nav > ul > li > ul > li a {
  background: rgba(33, 37, 157, 0.9);
  padding-left: 2em;
  font-size: 90%;
}

@media (min-width: 1480px) {
  header .global_nav > ul > li.has_under {
    position: relative;
    cursor: pointer;
    user-select: none;
  }
  header .global_nav > ul > li.has_under > a {
    padding-right: 2em;
  }
  header .global_nav > ul > li.has_under > a:after {
    border-color: #333;
    width: 6px;
    height: 6px;
  }
  header .global_nav > ul > li.has_under > a:after {
    transform: translate(0, -50%) rotate(135deg);
  }
  header .global_nav > ul > li.has_under > a.menu_under_open:after {
    transform: translate(0) rotate(-45deg);
  }
  header .global_nav > ul > li.has_under > ul {
    position: absolute;
    right: 0;
    margin: auto;
    top: 100%;
    width: 200px;
    letter-spacing: -.40em;
    background: rgba(29, 32, 136, 0.9);
    z-index: 999;
  }
  header .global_nav > ul > li.has_under > ul > li {
    letter-spacing: normal;
    display: block;
    vertical-align: middle;
    width: 100%;
    text-align: center;
    border-bottom: 0;
    padding: 0;
  }
  header .global_nav > ul > li.has_under > ul > li:not(:last-child) {
    border-bottom: white solid 1px;
  }
  header .global_nav > ul > li.has_under > ul > li a {
    display: block;
    background: transparent;
    padding: .5em;
    color: #fff;
  }
  header .global_nav > ul > li.has_under > ul > li a:before {
    content: none;
  }
  header .global_nav > ul > li.has_under:hover > a:after {
    transform: translate(0) rotate(-45deg);
  }
  header .global_nav > ul > li.has_under:hover > ul {
    opacity: 1;
    height: auto;
    visibility: visible;
  }
}

.pagetop {
  position: fixed;
  bottom: 20px;
  right: 5%;
  z-index: 98;
}

.pagetop a {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  padding: 0;
  background: rgba(243, 143, 0, 0.9);
}

@media (min-width: 768px) {
  .pagetop a {
    background: url(/wp-content/uploads/pagetop.png);
    width: 84px;
    height: 84px;
  }
}

@media (max-width: 767px) {
  .pagetop a {
    border-radius: 50%;
  }
}

@media (max-width: 767px) {
  .pagetop a:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    width: 12px;
    height: 12px;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    transform: translate(-50%, -20%) rotate(45deg);
    transition: .2s;
  }
}

footer.high .pagetop {
  position: absolute;
  bottom: auto;
  right: 5%;
  top: 0;
  margin: 0;
  transform: translateY(-50%);
}

.qa.qa_active .answer {
  display: none;
}

.qa.qa-open .question .answer {
  display: block;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.lead {
  color: #1d2088;
  font-size: 20px;
  font-size: 2rem;
}

.text-xxlarge {
  font-size: 24px;
  font-size: 2.4rem;
}

.text-xlarge {
  font-size: 20px;
  font-size: 2rem;
}

.text-large {
  font-size: 16px;
  font-size: 1.6rem;
}

.text-small {
  font-size: 12px;
  font-size: 1.2rem;
}

.text-xsmall {
  font-size: 10px;
  font-size: 1rem;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-red {
  color: #f00 !important;
}

.text-mosGreen {
  color: #002222 !important;
}

.text-navy {
  color: #001f6a !important;
}

.text-primary {
  color: #1d2088 !important;
}

.bg-skyBlue {
  background: #dfedf7;
  padding: 70px 0;
}

@media (max-width: 767px) {
  .bg-skyBlue {
    padding: 30px 0;
  }
}

@media (max-width: 767px) {
  .sp-hide, .pc-only, .tablet-only {
    display: none !important;
  }
  .sp-text-center {
    text-align: center;
  }
  .sp-text-left {
    text-align: left;
  }
  .sp-text-right {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .lead {
    font-size: 28px;
    font-size: 2.8rem;
  }
  .text-xxlarge {
    font-size: 36px;
    font-size: 3.6rem;
  }
  .text-xlarge {
    font-size: 28px;
    font-size: 2.8rem;
  }
  .text-large {
    font-size: 20px;
    font-size: 2rem;
  }
  .text-small {
    font-size: 12px;
    font-size: 1.2rem;
  }
  .text-xsmall {
    font-size: 10px;
    font-size: 1rem;
  }
  .pc-hide, .sp-only {
    display: none !important;
  }
  .pc-text-center {
    text-align: center;
  }
  .pc-text-left {
    text-align: left;
  }
  .pc-text-right {
    text-align: right;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .tablet-hide {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .tablet-only {
    display: none !important;
  }
}

@media (max-width: 1479px) {
  .sp-hide02, .pc-only02, .tablet-only02 {
    display: none !important;
  }
}

@media (min-width: 1480px) {
  .pc-hide02, .sp-only02 {
    display: none !important;
  }
}

.arial {
  font-family: 'Arial', arial !important;
}

.arial_n {
  font-family: 'Arial Narrow', arial !important;
}

.color_pri {
  color: #1d2088;
}

.mt0 {
  margin-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.m-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.inverse h2,
.inverse h3,
.inverse h4,
.inverse p {
  color: #fff;
}

.inverse h2:after {
  background: #fff;
}

.inverse h2 small,
.inverse h2 span {
  color: #fff;
}

.image_right {
  margin-bottom: 1em;
}

@media (min-width: 768px) {
  .image_right {
    display: inline-block;
    float: right;
    margin-left: 1em;
  }
}

a:not([class]) img,
a.swipebox img {
  transition: all 0.3s;
}

a:not([class]):hover img,
a.swipebox:hover img {
  opacity: 0.8;
}

@media (min-width: 768px) {
  .d-flex {
    display: flex;
  }
  .d-flex.flex-wrap {
    flex-wrap: wrap;
  }
  .d-flex.flex-nowrap {
    flex-wrap: nowrap;
  }
  .flex-row-reverse {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .align-items-start {
    align-items: flex-start;
  }
  .align-items-center {
    align-items: center;
  }
  .align-items-end {
    align-items: flex-end;
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-between {
    justify-content: space-between;
  }
}

.dsp-home-only {
  display: none;
}

.dsp-under-only {
  display: block;
}

.home .dsp-home-only {
  display: block;
}

.home .dsp-under-only {
  display: none;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mt-3 {
  margin-top: 3em;
}

.mt-4 {
  margin-top: 4em;
}

.mt-5 {
  margin-top: 5em;
}

.js-tel a {
  color: currentColor;
  text-decoration: none;
}

@media (min-width: 768px) {
  .js-tel a {
    pointer-events: none;
  }
}

.ofi.cover img {
  object-fit: cover;
  font-family: "object-fit: cover;";
  width: 100%;
  height: 100%;
}

.icon-tel:before {
  content: "\f879";
  font-family: "Font Awesome 5 Free";
  margin-right: 5px;
}

body.english .jp-only {
  display: none;
}

body:not(.english) .en-only {
  display: none;
}

body.home h2 {
  font-size: 60px;
  font-size: 6rem;
  color: #1d2088;
  font-weight: normal;
  padding: 0;
}

@media (max-width: 767px) {
  body.home h2 {
    font-size: 35px;
    font-size: 3.5rem;
  }
}

body.home h2:after {
  top: 85px;
  bottom: auto;
}

@media (max-width: 767px) {
  body.home h2:after {
    top: 55px;
  }
}

body.home h2 .sub {
  display: block;
  color: #333;
  font-size: 28px;
  font-size: 2.8rem;
  padding-top: 0.8em;
}

@media (max-width: 767px) {
  body.home h2 .sub {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.top_work {
  padding: 10% 0;
}

@media (min-width: 1023px) {
  .top_work {
    background: url(/wp-content/uploads/bg_img001.jpg) no-repeat center/cover;
  }
}

@media (min-width: 981px) and (max-width: 1170px) {
  .top_work {
    padding: 5% 0;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .top_work {
    background: url(/wp-content/uploads/bg_img001-1.jpg);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .top_work .col.tablet-only {
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .top_work h2:after {
    left: 0;
    right: auto;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .top_work h2 .sub {
    font-size: 3vw !important;
  }
}

.top_regal {
  padding: 10% 0;
  background: url(/wp-content/uploads/bg_img002.jpg) no-repeat center/cover;
}

@media (max-width: 767px) {
  .top_regal {
    background: url(/wp-content/uploads/sp_bg_img002.jpg);
  }
}

@media (min-width: 768px) {
  .top_regal h2:after {
    left: 0;
    right: auto;
  }
}

.top_info {
  padding: 5% 0;
  position: relative;
}

.top_info:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  top: 0;
  bottom: auto;
  z-index: -1;
  background: url(/wp-content/uploads/bg_img003.jpg) no-repeat center top/100%;
}

@media (max-width: 767px) {
  .top_info:before {
    background: url(/wp-content/uploads/bg_img003.jpg) no-repeat center top;
  }
}

.top_info .news_inner {
  padding: 5%;
  background: #fff;
  margin: 0 -5%;
}

@media (max-width: 767px) {
  .top_info .news_inner {
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .top_info .tab_header {
    width: 32%;
  }
}

.top_info .tab {
  overflow: hidden;
}

.top_info .tab .tab__button {
  display: block;
}

@media (max-width: 767px) {
  .top_info .tab .tab__button {
    width: 50%;
    float: left;
  }
}

.top_info .tab .tab__button p a {
  display: inline-block;
  max-width: 290px;
  width: 100%;
  margin: 0;
  padding: 1.2em 12% 1.2em 54px;
  border-bottom: #e4e4f3 solid 2px;
  border-radius: 0px;
  line-height: 1;
  background: transparent;
  color: #333;
  text-align: left;
  text-decoration: none;
  appearance: none;
  transition: 0.3s;
  font-size: 16px;
  font-size: 1.6rem;
  cursor: pointer;
  position: relative;
}

.top_info .tab .tab__button p a:after {
  transform: none;
  content: "";
  display: block;
  position: absolute;
  border: none;
  width: 5px;
  height: 9px;
  top: 0;
  bottom: 0;
  left: auto;
  right: 8%;
  margin: auto;
  background: url(/wp-content/uploads/btn_arrow2.png) no-repeat center;
  transition: 0.3s;
}

.top_info .tab .tab__button p a:hover:after {
  right: 6%;
}

.top_info .tab .tab__button p a:before {
  content: "";
  display: block;
  position: absolute;
  width: 50px;
  height: 30px;
  top: 0;
  bottom: 0;
  right: auto;
  left: 3px;
  margin: auto;
}

.top_info .tab .tab__button p a.media_btn:before {
  background: url(/wp-content/uploads/icon001.png);
}

.top_info .tab .tab__button p a.seminar_btn:before {
  background: url(/wp-content/uploads/icon002.png);
}

.top_info .tab .tab__button p a.book_btn:before {
  background: url(/wp-content/uploads/icon003.png);
}

.top_info .tab .tab__button p a.news_btn:before {
  background: url(/wp-content/uploads/icon004.png);
}

.top_info .tab .tab__button.active p a {
  border-bottom: 2px solid #1d2088;
}

.top_lawer {
  padding: 10% 0;
  background: url(/wp-content/uploads/bg_img004.jpg) no-repeat center/cover;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .top_lawer {
    background: url(/wp-content/uploads/bg_img004-1.jpg) no-repeat center/cover;
  }
}

@media (max-width: 767px) {
  .top_lawer {
    background: url(/wp-content/uploads/sp_bg_img004.jpg) no-repeat center/cover;
  }
}

.top_lawer .name {
  font-size: 1.5em;
  font-weight: bold;
}

@media (min-width: 768px) {
  .top_lawer h2:after {
    left: 0;
    right: auto;
  }
}

.top_access {
  padding: 6% 0;
  background: url(/wp-content/uploads/bg_img005.jpg) no-repeat center/cover;
}

.top_access ul.access_data {
  padding: 0;
}

.top_access ul.access_data li {
  list-style: none;
  padding: 0.5em 0;
  border-top: solid 1px #798db9;
}

.top_access ul.access_data li:last-child {
  border-bottom: solid 1px #798db9;
}

.top_access .con_btn a {
  background: #f38f00;
  border-color: #f38f00 !important;
  max-width: 250px;
}

.top_access .con_btn a:hover {
  background: #ff9c0e;
}

@media (max-width: 767px) {
  .top_access .ac_logo {
    margin-top: 2em;
  }
}

.top_access .ac_btn_wrap p a {
  margin: 0 auto;
  text-align: left;
  padding: 1em 0 1em 1.2em;
  display: flex;
  align-items: center;
  height: 84px;
}

@media (min-width: 768px) {
  .top_access .ac_btn_wrap p a {
    max-width: 100%;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media (max-width: 767px) {
  .top_access .ac_btn_wrap p a {
    max-width: 250px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.top_access .ac_btn_wrap p a > span {
  position: relative;
  padding-left: 45px;
  display: block;
}

.top_access .ac_btn_wrap p a > span:before {
  content: "";
  display: block;
  position: absolute;
  width: 36px;
  height: 31px;
  top: 0;
  bottom: 0;
  right: auto;
  left: 0;
  margin: auto;
}

.top_access .ac_btn_wrap p.youtube_btn a {
  background: #ff0000;
  border-color: #ff0000;
}

.top_access .ac_btn_wrap p.youtube_btn a > span:before {
  background: url(/wp-content/uploads/youtube_w.png);
}

.top_access .ac_btn_wrap p.youtube_btn a:hover {
  background: #ff3333;
  border-color: #ff3333;
}

.top_access .ac_btn_wrap p.fb_btn a {
  background: #1877f2;
  border-color: #1877f2;
}

.top_access .ac_btn_wrap p.fb_btn a > span:before {
  background: url(/wp-content/uploads/facebook_w.png);
}

.top_access .ac_btn_wrap p.fb_btn a:hover {
  background: #3085f3;
  border-color: #3085f3;
}

.top_access .ac_btn_wrap p.more_btn a > span:before {
  background: url(/wp-content/uploads/access.png);
}

.dib {
  display: inline-block;
}

.ul01 {
  padding-left: 1em;
}

.ul01 li {
  list-style: none;
  position: relative;
  margin-bottom: 0.3em;
  padding-left: 1em;
  box-sizing: border-box;
}

.ul01 li:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background: #f38f00;
  top: 6px;
  left: 0;
}

.ul02 {
  padding-left: 1em;
  margin-bottom: 1.5em;
}

.ul02 li {
  list-style: none;
  position: relative;
  margin-bottom: 0.3em;
  padding-left: 1em;
  box-sizing: border-box;
}

.ul02 li:before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background: #f38f00;
  top: 8px;
  left: 0;
}

@media (min-width: 768px) {
  .float-list {
    overflow: hidden;
  }
  .float-list li {
    width: 50%;
    margin: 0 0 0.5em 0;
    padding-right: 0.8em;
    float: left;
  }
  .float-list02 {
    overflow: hidden;
  }
  .float-list02 li {
    width: 33%;
    margin: 0 0 0.5em 0;
    padding-right: 0.8em;
    float: left;
  }
}

.ul_arrow {
  padding: 20px 20px 10px;
  box-sizing: border-box;
  margin: 0 auto 2em;
  list-style: none;
}

.ul_arrow li {
  position: relative;
  margin-bottom: 0.5em;
  padding-left: 1.2em;
}

.ul_arrow li:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 0.35em;
  left: 7px;
  margin: 0 0 0 0;
  border: 6px solid transparent;
  border-top: 8px solid #f38f00;
  transform: rotate(-90deg);
}

.ul_arrow li a {
  text-decoration: none;
  color: #333;
}

.ul_arrow li a:hover {
  text-decoration: underline;
}

.table-custom {
  width: 100%;
}

.table-custom th {
  text-align: left;
}

.table-custom td {
  text-align: right;
}

@media (min-width: 768px) {
  .table-custom td {
    width: 170px;
  }
}

@media (max-width: 767px) {
  .table-custom td {
    width: 135px;
  }
}
