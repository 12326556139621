@charset "utf-8";
.pagetop {
  position: fixed;
  bottom: 20px;
  right: 5%;
  z-index: 98;
  //@media ( max-width : 767px ) {
  //  bottom: 10%;
  //}
  a {
    @include image-replace();
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    @media ( min-width : 768px ) {
      background: url(/wp-content/uploads/pagetop.png);
      width: 84px;
      height: 84px;
    }
    @media ( max-width : 767px ) {
    border-radius: 50%;
      
    }
    padding: 0;
    background: $color_pagetop_bg;
    &:after {
      @media ( max-width : 767px ) {
         content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: auto;
      width: 12px;
      height: 12px;
      border-top: 1px solid $color_pagetop_arrow;
      border-left: 1px solid $color_pagetop_arrow;
      transform: translate(-50%, -20%) rotate(45deg);
      transition: .2s;
      }
     
    }
  }
}
footer {
  &.high {
    .pagetop {
      position: absolute;
      bottom: auto;
      right: 5%;
      top: 0;
      margin: 0;
      transform: translateY(-50%);
    }
  }
}