@charset "utf-8";
// ver0.0.1
$base_font_size: 16; //ベースフォントサイズ（単位px）
$sp_base_font_size: 16; //SP用ベースフォントサイズ（単位px）
$header_font_size: 14; //ヘッダフォントサイズ ナビゲーション等の兼ね合いで収まらない場合に調整する（単位px）
$footer_font_size: 14; //フッタフォントサイズ （単位px）
$container: 1000; // container幅（単位px）
$header_break_point: 1480; // ヘッダーのブレイクポイント（ロゴ/Gナビの調整が難しい場合1000px等に設定する）
$fixed_header: false; // 固定ヘッダー true or false（未実装）
$header_search: false; //グローバルメニューの検索ボタン（未実装）

//TODO z-index管理用関数

//カラー設定
@import "color";

// ======== フォント設定
// Noto Sans
$font_base_gothic_n: -apple-system, BlinkMacSystemFont, Arial, "Noto Sans JP", sans-serif;

// 游ゴシック
$font_base_gothic_y: -apple-system, BlinkMacSystemFont, Arial, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;

// メイリオ
$font_base_gothic_m: -apple-system, BlinkMacSystemFont, Arial, Meiryo, sans-serif;

// 明朝
$font_base_mincho: "Times New Roman", "Noto Serif JP", serif;

$base_font_family: $font_base_mincho;

// ======== フォントインポート
// 使わないフォントは必ずコメントアウトすること
// 使いすぎはサイトパフォーマンスに影響するので使いすぎない（最大2つまで）
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&amp;subset=japanese'); //Noto Sans
//@import url('https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,700&display=swap'); //Noto Serif
//@import url('https://fonts.googleapis.com/icon?family=Material+Icons'); //Material Icon
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css'); //FontAwesome5

// ======== common
// svg形式のロゴのサイズを正確に入れる（PSDまたはSVGコードを参照すること）
$logo_width: 530; // ロゴの横幅
$logo_height: 36; // ロゴの縦幅
$logo_tab_width: 500; //タブレット時のロゴ横幅（横768～1000）
$logo_sp_width: 200; // スマホ時のロゴ横幅　（横320に対して、ハンバーガーメニューを加えても余裕のある値にしておく）

$header_height: 120; // ヘッダーの高さ
$sp_header_height: 80; // SPヘッダーの高さ

$header_type: A; // ヘッダタイプ　'A'（後日追加予定）
$header_container: false; // ヘッダをコンテナサイズに含めるか　true（コンテナ幅） or false（モニター幅）
$header_alpha: false; // ヘッダの高さを無くし透過させるか　true or false（未実装）
$navi_event_type: 'hover'; // グローバルナビ ドロップダウンの動作方法　'click' or 'hover'

//$header_menu_type: A; // ヘッダドロップダウンメニュータイプ　'A' or 'B' or 'C'（メガメニュー含めて実装予定）

// ======== main_visual
// スライドの種類
// true ・・・ fade
// false ・・・ slide
//各種オプションはmain.jsで編集すること
$main_slide_width: 1920; //スライド画像のwidth
$main_slide_height: 719; //スライド画像のheight
$main_slide_sp_width: 750;
$main_slide_sp_height: 860;
$main_slide_correct: ($main_slide_sp_height / $main_slide_sp_width)*100; //spサイズ時のpadding-topの値(%)
$main_slide_break_point: 768; // PC・SP画像を切替えるブレイクポイント

// スライド画像の見せ方
// 'A'・・・ブレイクポイント～メイン画像の最大サイズまでは高さが最大サイズで固定となるスライド。
// 'B'・・・常に画像全体が見えているスライド。縦横比率を維持しながらリサイズされる
// 'C'・・・全画面スライド。常にウィンドウの高さ＝メインビジュアルの高さとなる。
$main_slide_type: A;

// スライド上のコピー画像
// スライドに応じてFadeするか true or false
// trueの場合、　.main_visual_slide li　と　.main_visual_copy li の数は
// 一致している必要がある
$main_slide_copy_sync: false; // スライドに応じてFadeするか true or false
$main_slide_copy_width: 483; //スライド上のコピー画像のwidth
$main_slide_copy_height: 286; //スライド上のコピー画像のheight

// ======== button
// 共通
$button_width: 200px; // ボタン幅（max-width + width:100%）

// デフォルトボタン
$style_button_border: $color_primary solid 1px; // ボーダースタイル
$style_button_border_radius: 0px; // 角丸(px)

// Type A
$shape_slider_arrow: 'square' !default; // 前後切り替えボタンの形状 'square' or 'circle'

// Type B
// コンテンツスライダー
$contents_slider_image_width: 450px; // 画像の幅
$contents_slider_image_height: 300px; // 画像の高さ
$contents_slider_caption_minheight: 40px; // キャプションの高さの最小値
$style_contents_slider_caption_border: 1px dashed #999; // キャプションの左右のボーダースタイルcaption)
$contents_slider_arrow_width: 10px; // 前後切り替えボタンの幅

// 設定ここまで
// ========================================================================================
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/mixin/_base.scss";


/* normalize */
@import "vendor/common.css";
@import "common";

//ヘッダー（パターンによって差し替え）
@import "header/hdrA";

@import "footer";

//以下変更しない
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/slider/_contents.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/slider/_typeA.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/slider/_typeB.scss";

@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/parts/_bread.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/parts/_button.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/parts/_inquiry.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/parts/_movie.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/parts/_news.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/parts/_shopinfo.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/parts/_sitemap.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/parts/_sns.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/parts/_sp_image_cols.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/parts/_table.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/parts/_wide_image.scss";

@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/core/_headline.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/core/_main_slide.scss";

@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/post/_archive.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/post/_post.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/post/_single.scss";

@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/other/_effect.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/other/_menu.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/other/_pagetop.scss";
@import "C:/xammp/htdocs/project/src/bizlawjapan.com/assets/sass/other/qa.scss";


@import "utility";

@import "custom";
