@charset "utf-8";

html {
  font-size: 62.5%;
  overflow-x: hidden;

  &.scrollPrevent {
    overflow: hidden;
    height: 100%;
  }
}

body {
  background: $color_body_bg;
  font-family: $base_font_family;
  @include body_fontsize($base_font_size);
  line-height: 1.5;
  color: $color_text;
  height: auto !important;
  overflow: hidden;
  @media (max-width: 767px) {
    @include body_fontsize($sp_base_font_size);
  }
}

main {
  overflow: hidden;
}

figure {
  margin: 0;
  text-align: center;

  img {
    max-width: 100%;
    height: auto;
  }

  figcaption {
    margin-top: 0.5em;
    text-align: left;
  }
}

a {
  color: $color_link;
  transition: all .3s;

  &:hover {
    color: $color_link_hover;
    text-decoration: none;
  }

  .highlight & {
    color: $color_highlight_link;

    &:hover {
      color: $color_highlight_link_hover;
    }
  }
}

img {
  vertical-align: middle;

  &.circle {
    border-radius: 50%;
  }
}

p {
  margin: 0.5em 0 1em;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

ol,
ul {
  margin: 0 0 1em;
  padding: 0 0 0 2.5em;
  line-height: 1.6;

  &:last-child {
    margin-bottom: 0;
  }

  ol,
  ul {
    margin: 0.5em 0;
    padding-left: 2em;

    &:last-child {
      margin-bottom: 0.5em;
    }
  }
}

ul.list-unstyled {
  padding-left: 0;
  list-style: none;

  ol,
  ul {
    padding-left: 2em;
  }
}

ul.list-inline {
  @include list-horizontal(7px);
  margin-bottom: $base_font_size + px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

main {
  section {
    margin-bottom: 10%;

    &.highlight:last-child {
      margin-bottom: 0;
      padding-bottom: 10%;
    }
  }
}

@media (min-width: 768px) {
  p {
    margin: 0.5em 0 1em;
  }

  main {
    section {
      margin-bottom: 5%;

      &:last-child {
        margin-bottom: 7%;
      }
    }
  }
}

@media (min-width: 768px) {
  .gutters .span_9.column_main {
    width: 70.83%;
  }

  .gutters .span_3.column_sub {
    width: 25%;
  }
}

@media (max-width: 900px) {
  .gutters .span_3.column_sub,
  .gutters .span_9.column_main {
    float: none;
    width: 100%;
    margin-left: 0;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .gutters .span_3.column_sub {
    .sidebar {
      letter-spacing: -.40em;

      .widget_wrap {
        letter-spacing: normal;
        display: inline-block;
        vertical-align: top;
        width: 49%;
        margin-left: 2%;

        &:nth-child(2n+1) {
          margin-left: 0;
        }
      }
    }
  }
}

// コンテナの最大幅
@media (min-width: 768px) {
  .container {
    max-width: $container+px;
  }
}

//@media (min-width: 1024px) {
//  .container {
//    padding: 0 10px;
//  }
//}
@media (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

.article_button {
  margin-top: 5%;
}

@media (min-width: 768px) {
  .article_detail,
  .article_list {
    &:not(.reverse) {
      .col {
        &.span_12 {
          + .span_4 {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.sidebar {
  visibility: hidden;

  > .widget_wrap {
    visibility: visible;
  }

  ul {
    @include listclear();
    margin-bottom: 6%;

    a {
      color: $color_text;
      text-decoration: none;
      display: block;
      padding: .5em;
      border-bottom: rgba($color_primary, 0.1) solid 1px;

      &:hover {
        color: $color_primary;
        background: rgba($color_primary, 0.05);
      }
    }
  }

}

.gutters .col.span_12 + .span_12 {
  margin-left: 0;
}

//.sitemap {
//  padding: 0;
//  margin: 0;
//  border-top: 1px solid #ccc;
//
//  li {
//    list-style: none;
//    padding: 10px 0 10px 15px;
//    border-bottom: 1px solid #ccc;
//
//    > ul {
//      padding: 0;
//      border-top: #ccc solid 1px;
//
//      > li {
//        border-bottom: 1px solid #ccc;
//        position: relative;
//
//        &:before {
//          content: "";
//          position: absolute;
//          left: 0;
//          top: 25px;
//          width: 6px;
//          height: 6px;
//          background: #ccc;
//          border-radius: 3px;
//        }
//
//        &:last-child {
//          border-bottom: none;
//          padding: 10px 0 0 15px;
//        }
//
//        .children {
//          > li {
//            padding: 10px 0 0 15px;
//
//            &:before {
//              content: "";
//              position: absolute;
//              left: 0;
//              top: 25px;
//              width: 6px;
//              height: 6px;
//              background: #ccc;
//              border-radius: 0;
//            }
//          }
//        }
//      }
//    }
//  }
//
//  a {
//    @include fontsize(16);
//    text-decoration: none;
//
//    &:hover {
//      text-decoration: underline;
//    }
//  }
//}
//@media (min-width : 768px) {
//  .sitemap {
//    a {
//      @include fontsize(22);
//    }
//  }
//}

.map_wide {
  #map_canvas,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    max-width: none;
  }
}

.slick-track {
  margin: 0 auto;
}

.col {
  dl {
    p {
      line-height: 1.4;
      margin: 0;
    }
  }

  p {
    line-height: 1.8;
  }
}

.container {
  padding: 0 10px;
  @media (min-width: ($container + 20)+px) {
    padding: 0;
  }

  .container {
    padding: 0;
  }
}

//@media (min-width: 576px){
//  .container {
//    max-width: 100%;
//  }
//}

.highlight {
  padding: 4% 0;
  background: $color_highlight_bg;
  color: $color_highlight_text;

  &.round {
    border-radius: 10px;
  }
}

p, .col {
  &.highlight {
    padding: 4%;
  }
}

