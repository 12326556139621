@charset "utf-8";
//ヘッダA
.globalHeader {
  background: $color_header_bg;
  color: $color_header_text;
  font-size: $header_font_size + px;

  &.high {
    @media (max-width: ($header_break_point - 1)+px) {
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    }
  }

  @media (max-width: 767px) {
    background: $color_header_bg;
    position: fixed;
    z-index: 999;
    width: 100%;
  }

  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0;
    height: $header_height + px;
    @if ($header_container == false) {
      max-width: 100%;
      .globalHeader_top {
        width: 98%;
        margin: 0 auto;
        padding-right: 180px;
      }
    }
    @media (max-width: ($header_break_point - 1)+px) {
      height: $sp_header_height + px;
    }
  }

  .globalHeader_top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .header_logo {
    width: $logo_width + px;
    height: $logo_height + px;
    margin: 0;
    @media (min-width: 768px) and(max-width: ($header_break_point - 1)+px) {
      width: $logo_tab_width + px;
      height: auto;
    }
    @media (max-width: ($header_break_point - 1)+px) {
      width: $logo_sp_width + px;
      height: auto;
    }

    a {
      display: block;
      height: auto;
      line-height: 0;

      img {
        max-width: 100%;
      }
    }
  }
}

.global_nav {
  width: auto;
  @if $navi_event_type != "click" {
    list-style-position: inside;
  }
  @media (max-width: ($header_break_point - 1)+px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 56;
    transition: all 0.3s;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  > ul {
    list-style: none;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    @media (min-width: $header_break_point+px) {
      margin-top: 65px;
    }
    @media (max-width: ($header_break_point - 1)+px) {
      display: block;
      //min-height: 100vh;
      background: $color_gnav_bg;
      width: 80%;
      transform: translateX(100%);
      transition: all 0.3s;
      height: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }

    li {
      @media (max-width: ($header_break_point - 1)+px) {
        border-bottom: $color_gnav_border_sp solid 1px;
        &:first-child {
          border-top: $color_gnav_border_sp solid 1px;
        }
      }

      a {
        color: $color_navlink_pc;
        text-decoration: none;
        padding: 0 1em;
        transition: all 0.3s;

        @media (min-width: $header_break_point+px) and(max-width:1600px) {
          padding: 0 0.3em;
        }
        @media (min-width: $header_break_point+px) {
          position: relative;
          // &:before {
          //   content: "";
          //   width: 1px;
          //   height: 0.8em;
          //   position: absolute;
          //   left: 0;
          //   top: 0;
          //   bottom: 0;
          //   margin: auto;
          //   background: black;
          // }
        }

        @media (max-width: ($header_break_point - 1)+px) {
          display: block;
          padding: 1em;
          color: $color_navlink_sp;
          position: relative;

          &:after {
            content: "";
            width: 8px;
            height: 8px;
            border: 0;
            border-top: solid 2px #fff;
            border-right: solid 2px #fff;
            transform: rotate(45deg);
            position: absolute;
            top: 50%;
            right: 1em;
            margin-top: -4px;
          }
        }

        &:hover {
          opacity: 0.8;
        }
      }

      &:first-child {
        a {
          &:before {
            content: none;
          }
        }
      }
    }
  }

  //@media (max-width: ($header_break_point - 1)+px) {
  //  display: none;
  //}

  &.open {
    @media (max-width: ($header_break_point - 1)+px) {
      opacity: 1;
      visibility: visible;
      left: 0;
      background: rgba(black, 0.8);
    }

    > ul {
      @media (max-width: ($header_break_point - 1)+px) {
        transform: translateX(0);
      }
    }
  }
}

/**ハンバーガーメニュー**/
#headerToggle {
  display: none;
}

@media (max-width: ($header_break_point - 1)+px) {
  #headerToggle {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 20px;
    z-index: 99;
    cursor: pointer;
    width: 40px;
    height: 40px;
    transition: all 0.3s;

    span {
      position: absolute;
      left: 0;
      width: 100%;
      height: 6px;
      background-color: $color_primary;
      transition: all 0.4s;

      &:nth-of-type(1) {
        top: 0;
      }

      &:nth-of-type(2) {
        top: 17px;
      }

      &:nth-of-type(3) {
        bottom: 0;
      }
    }

    &.active {
      right: calc(80% + 20px);

      span {
        background-color: white;

        &:nth-of-type(1) {
          transform: translateY(16px) rotate(-45deg);
        }

        &:nth-of-type(2) {
          left: 50%;
          opacity: 0;
        }

        &:nth-of-type(3) {
          transform: translateY(-17px) rotate(45deg);
        }
      }
    }
  }
}

//ヘッダ電話番号等
.hdr_info_wrapper {
  display: none;
  @media (min-width: $header_break_point+px) {
    display: block;
    position: absolute;
    top: 15px;
    right: 180px;
  }

  .hdr_info {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }

  .hdr_info_group {
    @include listclear();
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li {
      margin-left: 10px;
    }

    .button {
      min-width: 140px;
      @include fontsize(14);
      background: transparent;
      color: $color_primary;
      &:after {
        // 疑似要素
        transform: none;
        content: "";
        display: block;
        position: absolute;
        border: none;
        width: 5px;
        height: 9px;
        top: 0;
        bottom: 0;
        right: auto;
        left: 10%;
        margin: auto;
        background: url(/wp-content/uploads/btn_arrow2.png) no-repeat center;
      }
      &:hover {
        background: rgba($color_primary, 0.1);
        &:after {
          left: 11%;
          right: auto;
        }
      }
    }
  }
}

.sp_hdr_info_wrapper {
  display: none;
  @media (max-width: ($header_break_point - 1)+px) {
    display: block;
    position: absolute;
    top: 13px;
    right: 75px;
  }
  @media (max-width: 480px) {
    top: 20px;
  }
  .sp_hdr_info_group {
    @include listclear();
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li {
      margin-left: 10px;
      img {
        @media (max-width: 480px) {
          width: 40px;
        }
      }
      &.hdr_icon_tel {
        color: white;
        a {
          color: $color_primary;
        }

        i {
          @include fontsize(32);
        }
      }

      &.hdr_icon_mail {
        color: white;

        a {
          color: $color_primary;
        }

        i {
          @include fontsize(32);
        }
      }
    }
  }
}

.hdr_contact {
  position: absolute;
  right: 0;
  left: auto;
  display: block;
  top: 0;
  bottom: auto;
  margin: 0;
  p {
    width: 180px;
    height: $header_height + px;

    position: relative;
    &:after {
      // 疑似要素
      content: "";
      display: block;
      position: absolute;
      width: 14px;
      height: 14px;
      top: auto;
      bottom: 10px;
      right: 10px;
      left: auto;
      margin: auto;
      background: url(/wp-content/uploads/btn_arrow.png) no-repeat center;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      // display: block;
      font-size: 17px;
      text-align: center;
      color: #fff;
      text-decoration: none;
      width: 100%;
      height: 100%;
          background: $color_secondary;

      &:hover {
        background: lighten( $color_secondary, 5%);
      }
    }
  }
}
