@charset "utf-8";

header {
  .global_nav {
    > ul {
      > li {
        // 2階層目
        &.has_under {
          > a {
            position: relative;

            &:after {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              right: 1em;
              //left: 0;
              bottom: 0;
              margin: auto;
              width: 7px;
              height: 7px;
              border-top: 2px solid $color_navlink_sp;
              border-right: 2px solid $color_navlink_sp;
              transform: translate(0, -50%) rotate(135deg);
              transition: .2s;
              //@media (max-width: ($header_break_point - 1)+px) {
              //  top: 0;
              //  bottom: 0;
              //  left: auto;
              //  right: 3%;
              //}
            }

            &.menu_under_open {
              &:after {
                transform: translate(0, 0) rotate(-45deg);
              }
            }
          }
        }

        > ul {
          @include listclear();
          display: none;

          > li {
            border-bottom: 1px solid $color_gnav_border_sp;
            &:last-child{
              border-bottom: none;
            }

            a {
              background: lighten($color_gnav_bg, 5%);
              padding-left: 2em;
              font-size: 90%;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $header_break_point+px) {
  header {
    .global_nav {
      > ul {
        > li {
          &.has_under {
            position: relative;
            cursor: pointer;
            user-select: none;

            > a {
              padding-right: 2em;

              &:after {
                border-color: $color_navlink_pc;
                width: 6px;
                height: 6px;
              }

              &:after {
                transform: translate(0, -50%) rotate(135deg);
              }

              &.menu_under_open {
                &:after {
                  transform: translate(0) rotate(-45deg);
                }
              }
            }

            > ul {
              position: absolute;
              right: 0;
              margin: auto;
              top: 100%;
              width: 200px;
              letter-spacing: -.40em;
              background: $color_gnav_bg;
              z-index: 999;

              > li {
                letter-spacing: normal;
                display: block;
                vertical-align: middle;
                width: 100%;
                text-align: center;
                border-bottom: 0;
                padding: 0;

                &:not(:last-child) {
                  border-bottom: white solid 1px;
                }

                a {
                  display: block;
                  background: transparent;
                  padding: .5em;
                  color: $color_white;

                  &:before {
                    content: none;
                  }
                }
              }
            }

            &:hover {
              > a {
                &:after {
                  transform: translate(0) rotate(-45deg);
                }
              }

              > ul {
                opacity: 1;
                height: auto;
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
}
